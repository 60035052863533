<template>
  <div
    class="pt-3 bg-white multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
        <div class="row mt-4 text-start">
            <h4>Standard Activites</h4> 
              <div>
                          <material-input
                          id="add-act" label="Add Standard Activity"
                          variant="static" class="mt-2 ms-0 mb-4" />
              </div> 
            <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Work at Heights</material-checkbox>
              <material-checkbox id="checkboxId">Confined Space Entry</material-checkbox>
              <material-checkbox id="checkboxId">Hazardous Substance</material-checkbox>
              <material-checkbox id="checkboxId">Mobile Plant</material-checkbox>
              <material-checkbox id="checkboxId">Roof Access</material-checkbox>
              <material-checkbox id="checkboxId">Power Tools</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Excavation >300mm</material-checkbox>
              <material-checkbox id="checkboxId">Excavation >1.5m</material-checkbox>
              <material-checkbox id="checkboxId">Working with Chemicals</material-checkbox>
              <material-checkbox id="checkboxId">Energised electrical work</material-checkbox>
              <material-checkbox id="checkboxId">Breakwater Access</material-checkbox>
              <material-checkbox id="checkboxId">Workshop Machinery</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Hot Works</material-checkbox>
              <material-checkbox id="checkboxId">Punt</material-checkbox>
              <material-checkbox id="checkboxId">Forklift Cage</material-checkbox>
              <material-checkbox id="checkboxId">Palfinger</material-checkbox>
              <material-checkbox id="checkboxId">Pits</material-checkbox>
              <material-checkbox id="checkboxId">Welding or Gas Cutting</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Pavement Inspection</material-checkbox>
              <material-checkbox id="checkboxId">Building Inspections</material-checkbox>
              <material-checkbox id="checkboxId">Survey Boat Operations</material-checkbox>
              <material-checkbox id="checkboxId">Working with Chemicals</material-checkbox>
              <material-checkbox id="checkboxId">Bollard Removal/Exchange</material-checkbox>
                        </div>
                        <div class="col-12 mt-4">
                        <h4>Risks</h4> 
              <div>
                          <material-input
                          id="add-act" label="Add Risk"
                          variant="static" class="mt-2 ms-0 mb-4" />
              </div> 
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Asbestos Exposure</material-checkbox>
              <material-checkbox id="checkboxId">Body Injury</material-checkbox>
              <material-checkbox id="checkboxId">Eye Damage</material-checkbox>
              <material-checkbox id="checkboxId">Burns</material-checkbox>
              <material-checkbox id="checkboxId">Trips, Slips, Falls </material-checkbox>
              <material-checkbox id="checkboxId">Strain</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Chemical Exposure</material-checkbox>
              <material-checkbox id="checkboxId">Collision</material-checkbox>
              <material-checkbox id="checkboxId">Engulfment</material-checkbox>
              <material-checkbox id="checkboxId">Electrocution</material-checkbox>
              <material-checkbox id="checkboxId">Explosion</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Crush</material-checkbox>
              <material-checkbox id="checkboxId">Sprains and Strains</material-checkbox>
              <material-checkbox id="checkboxId">Hearing Loss</material-checkbox>
              <material-checkbox id="checkboxId">Drowning</material-checkbox>
              <material-checkbox id="checkboxId" >Falling Objects</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3 mb-4">
              <material-checkbox id="checkboxId">Falling from Height</material-checkbox>
              <material-checkbox id="checkboxId">Asphyxiation</material-checkbox>
              <material-checkbox id="checkboxId">Noxious Gases</material-checkbox>
              <material-checkbox id="checkboxId">Fire</material-checkbox>
              <material-checkbox id="checkboxId">Foot Injury</material-checkbox>
              <material-checkbox id="checkboxId">Hand Injury</material-checkbox>
            </div>
        </div>
      <div class="row">
        <div class="mt-4 button-row d-flex col-12">
          <button
            class="mb-0 btn bg-gradient-light js-btn-prev"
            type="button"
            title="Prev"
            @click="$parent.prevStep"
          >
            Prev
          </button>
        <button
          class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="$parent.nextStep"
        >
          Next
        </button>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import MaterialCheckbox from "@/components/materialKit/MaterialCheckbox.vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";

export default {
  name: "Activites",
  components: {
    MaterialCheckbox,MaterialInput
  },
};
</script>
