import axios from "axios";

export default class EsriHelper {
    UsePlatform = true;
    UseProxy = false;
    WebmapId = "";
    ApiKey = "";
    CompanyId = 1
 
    async initialize(urlUtils) 
    {
        if(this.UsePlatform)
        {
            //Set to specific company
            switch (this.CompanyId)
            {
                case 1: //Napier Port
                this.ApiKey = "AAPK08e99ea7826b412bbf9505df6fc547c9Jz497PBYnKW2tcdm3zUbzOd6NQu8uUOpS-d7thkCPskwqGbbJVqIV0xZISlrbVBA";
                this.WebmapId = "9c8df85521994e8080c25957dbd331cd";
                break;

                default: //Default to all companies
                this.ApiKey = "AAPKefecbc49e553441c89d88df728cd13efFOGFrdLl0MZhh1OZAwm_U3OqCGrfpv06ReWCc124rpq5ObXwGg9J9ETOubidi5l-";
                this.WebmapId = "c185b0355d2145aa90f1c657be974732";
            }
        }
        else {
            //use AGOL
            this.ApiKey = await this.getArcGISOnlineToken();
            this.WebmapId = "91828ff7d728404a8edee246e1f956c5";
        }

        //use proxy (if required)
        if (this.UseProxy)
        {
            this.ApiKey = "PROXY_KEY";
            this.setProxyServer(urlUtils)
        }
    }

    setProxyServer(urlUtils) {
        if (this.UseProxy)
        {           
            // Ideally would use code:
            //  config.request.proxyUrl = "https://localhost:7159/api/Proxy";
            //  config.request.forceProxy = true;
            // but does not work, we need to add a proxy rule for every esri url used
            urlUtils.addProxyRule({
                urlPrefix: "www.arcgis.com",
                proxyUrl: "https://localhost:7159/api/Proxy"
            });
            urlUtils.addProxyRule({
                urlPrefix: "tiles.arcgis.com",
                proxyUrl: "https://localhost:7159/api/Proxy"
            });
            urlUtils.addProxyRule({
                urlPrefix: "services8.arcgis.com",
                proxyUrl: "https://localhost:7159/api/Proxy"
            });
            urlUtils.addProxyRule({
                urlPrefix: "services5.arcgis.com",
                proxyUrl: "https://localhost:7159/api/Proxy"
            });
            urlUtils.addProxyRule({
                urlPrefix: "services-ap1.arcgis.com",
                proxyUrl: "https://localhost:7159/api/Proxy"
            });
        }
    }

    async getArcGISOnlineToken()
    {
      // ArcGIS ONLINE
      // =============
      // USE url below to get a temp token 
      // https://www.arcgis.com/sharing/rest/oauth2/token?client_id=aBm1Fn7TLC39ws2W&client_secret=23ae318092f64faaa83d51e3d84ad2f3&grant_type=client_credentials
      
      // here is the url split up
      // https://www.arcgis.com/sharing/rest/oauth2/token?
      // client_id=aBm1Fn7TLC39ws2W&
      // client_secret=23ae318092f64faaa83d51e3d84ad2f3&
      // grant_type=client_credentials
  
      // Or can use the below to get it dynamically
      const response = await axios.get(
        "https://www.arcgis.com/sharing/rest/oauth2/token?client_id=aBm1Fn7TLC39ws2W&client_secret=23ae318092f64faaa83d51e3d84ad2f3&grant_type=client_credentials"
      )
      let apiToken = response.data.access_token;
      console.log("got temp token: " + apiToken);
      return apiToken;
    }
      
}