<template>
  <div
    class="pt-3 bg-white multisteps-form__panel js-active position-relative"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="mt-3 row text-start">
        <div class="mt-4 col-12 col-sm-6 d-flex">
          <material-radio id="site-work" name="radio" checked>Site Works</material-radio>
          <material-radio id="permit" name="radio">Permit</material-radio>
        </div>
        <div class="col-12 col-sm-6 text-start">
                <div class="d-block">
                <label class="ms-0">Template</label>
                <select id="template" class="form-control" name="Status" >
                    <option value="Confined Space" selected>Confined Space</option>
                    <option value="Bunkering">Bunkering</option>
                    <option value="Corridor Access">Corridor Access</option>
                    <option value="Crane and Heavy Lifts">Crane and Heavy Lifts</option>
                    <option value="Dive Ops">Dive Ops</option>
                </select>
                </div>
        </div>
      </div>
      <div class="mt-3 row">
              <div class="col-12 text-center">Or 
                <button
                  class="btn bg-gradient-primary mb-0 toast-btn me-2"
                  type="button"
                  @click="showPreset"
                >
                  Create from preset
                </button>
            </div>
      </div>
        <div v-show="isPreset" class="mt-3 row">
          <div class="mt-4 col-12 col-sm-6 mt-sm-0 text-start">
                <div class="d-block">
                <label class="mt-2 ms-0">Selected Preset</label>
                <select id="preset" class="form-control" name="Status" >
                    <option value="Confined Space" selected>Confined Space</option>
                    <option value="Bunkering">Bunkering</option>
                    <option value="Corridor Access">Corridor Access</option>
                    <option value="Crane and Heavy Lifts">Crane and Heavy Lifts</option>
                    <option value="Dive Ops">Dive Ops</option>
                </select>
                </div>
              </div>
          <div class="mt-4 col-12 col-sm-6 mt-sm-0 text-end">
              <button 
                  class="btn bg-gradient-dark mb-0 mt-4 toast-btn me-2"
                  type="button" @click="hideAll">Clear Preset</button>
            </div>
      </div>
      <div class="mt-3 row text-start">
      <h4>Details</h4>
          <div class="d-block mb-4">
            <material-input
              id="title"
              variant="dynamic"
              label="Project Title"
            />
          </div>
          <div class="d-block mb-4">
            <material-input
              id="applicant"
              variant="dynamic"
              label="Applicant"
            />
          </div>
          <div class="d-block mb-4">
            <material-input
              id="related"
              variant="dynamic"
              label="Related to"
            />
          </div>
          <div class="d-block mb-4">
                <material-input
                id="applicant" label="Qualifications Required"
                variant="static" class="mt-4 ms-0"/>
          </div>
      </div>
      <div class="mt-3 row text-start">
      <h4>Who will be conducting the work</h4>
        <div class="col-12">
                <div class="d-block">
                <select id="people" ref="skills" multiple class="form-control" name="Status" >
                    <option value="">No one Selected</option>
                    <option value="Bob Guy" >Bob Guy</option>
                    <option value="The Electrician">The Electrician - Contractor</option>
                    <option value="Fun Time">Fun Times</option>
                    <option value="Mary Onthestop">Mary Onthestop</option>
                    <option value="Plumbers">Plumbers - Contractor</option>
                    <option value="Those helpful guys">Those helpful guys - Contractor</option>
                </select></div>
        </div>
      </div>
      <div class="mt-3 row text-start">
        <div class="col-6 col-sm-9">
          <p class="mt-0">If you cannot find who is conducting the work they have not been added to the system. </p>
        </div>
        <div class="col-6 col-sm-3">
              <router-link to="/add-people">
                <button
                  class="btn bg-gradient-primary mb-0 toast-btn me-2"
                  type="button"
                >
                  Add people
                </button></router-link>
        </div>
      </div>
      <div class="mt-4 button-row d-flex">
        <button
          class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="$parent.nextStep"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialRadio from "@/components/materialKit/MaterialRadio.vue";
import Choices from "choices.js";

export default {
  name: "Start",
  components: { MaterialInput,MaterialRadio },
  data() {
    return {
      isTemplate: false,
      isPreset: false,
    };
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);

    this.getChoices("template");
    this.getChoices("preset");
    
    if (document.getElementById("people")) {
      var skills = document.getElementById("people");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }
  },
  methods: {
  getChoices(id) {
    if (document.getElementById(id)) {
      var element = document.getElementById(id);
      return new Choices(element, {
        searchEnabled: true,
        allowHTML: true,
      });
    }
  },
        showPreset() {
            this.isTemplate = false;
            this.isPreset = true;
        },
        hideAll() {
            this.isTemplate = false;
            this.isPreset = false;
        }
  },
};
</script>
