<template>
          <div class="table-responsive">
            <table id="hazards" class="table table-flush" >
              <thead class="thead-light">
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Status
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Title
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    First Reported
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Last Updated
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Location
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Linked to incidents
                  </th>
                </tr>
              </thead>
              <tbody>
        <tr v-for="item in sortedList" :key="item.id" :data-id="item.id">
          <td class="text-sm font-weight-normal">
            <button
                  class="btn w-100 mb-0 p-0 text-white"
            :class="{
              new: item.status == 'New',
              openIcid: item.status == 'Open Hazard',
              closed: item.status == 'Closed',
            }">
            {{ item.status }}
                </button></td>
          <td class="text-sm font-weight-normal">{{ item.title }}</td>
          <td class="text-sm font-weight-normal">{{ item.reportDate }}</td>
          <td class="text-sm font-weight-normal">{{ item.updatedDate }}</td>
          <td class="text-sm font-weight-normal">{{ item.location }}</td>
          <td class="text-sm font-weight-normal">
            <button
                  class="btn w-100 mb-0 p-0 text-white"
            :class="{
              openIcid: item.incidents == 'Linked',
            }">
            {{ item.incidents }}
                </button></td>
        </tr>
              </tbody>
            </table>
          </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import moment from "moment";

export default {
  name: "HazardsTable",
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dataTable: null,
    };
  },
  computed: {
    sortedList() {
      // Sort the list by occur Date
      return this.list.slice().sort((a, b) => {
        const dateA = moment(a.reportDate, "DD/MM/YYYY");
        const dateB = moment(b.reportDate, "DD/MM/YYYY");
        return dateB - dateA;
      });
    },
  },
  mounted() {
    this.initDataTable();
  },
  methods: {
    initDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
      }
      this.dataTable = new DataTable("#hazards", {
        searchable: true,
        fixedHeight: true,
      });  
      // Add link to row
      const tableBody = document.querySelector("#hazards tbody");
        const rows = tableBody.querySelectorAll("tr");
        rows.forEach((row) => {
          const id = row.dataset.id;
          row.addEventListener("click", () => {
            console.log('Row clicked', id); // Verify the click event
            this.$router.push(`/hazard/${id}`);
          });
      });
},
      // view by status
    showNew() {
      this.initDataTable();
      const tableBody = document.querySelector("#hazards tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "New") {
          row.remove();
        }
      });
    },
    showOpen() {
      this.initDataTable();
      const tableBody = document.querySelector("#hazards tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Open Hazard") {
          row.remove();
        }
      });
    },
    showClosed() {
      this.initDataTable();
      const tableBody = document.querySelector("#hazards tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Closed") {
          row.remove();
        }
      });
    },
    showLinked() {
      this.initDataTable();
      const tableBody = document.querySelector("#hazards tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(6)");
        if (statusCell && statusCell.textContent.trim() !== "Linked") {
          row.remove();
        }
      });
    },

    showAll() {
      this.initDataTable();
    },
  },
};
</script>
<style scoped>
tr{
  cursor: pointer;
}
</style>