<template>
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://tucumcari.co.nz/wp-content/uploads/2023/07/Piles-2low.jpg');
    "
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-dark shadow-info border-radius-lg py-3 pe-1"
              >
      <router-link to="/">
        <img
          src="@/assets/img/TucumcariLogo-Full-TransaprentBack.png
          "
          class="navbar-brand-img w-70 pb-3 mx-auto d-block position-relative"
          alt="main_logo"
        />
      </router-link>
              </div>
            </div>
            <div class="card-body">
                <h4 class="font-weight-bolder text-center mt-3 mb-0">
                  Sign in
                </h4>
            <form role="form" class="text-start">
              <div class="mb-4">
                <material-input
                  id="email"
                  type="email"
                  placeholder="john@email.com"
                  name="email"
                  label="Email"
                  variant="static"
                />
              </div>
              <div class="mb-4">
                <material-input
                  id="password"
                  type="password"
                  placeholder="password"
                  name="password"
                  label="Password"
                  variant="static"
                />
              </div>
              <div class="text-center">
                <material-button
                  class="my-4 mb-2"
                  variant="gradient"
                  color="dark"
                  full-width href="/location"
                  >Sign in</material-button
                >
              </div>
            </form>
                <p class="mt-4 text-sm text-center">
                  Forgot your password ?
                  <router-link
                    to="/reset"
                    class="text-info text-gradient font-weight-bold"
                    >Reset password</router-link
                  >
                </p>
                <p class="mt-4 text-sm text-center">Note: You will need to sign in again to select a new location</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
            ©
            {{ new Date().getFullYear() }}, developed and delivered by
            <a
              href="https://firecrest.co.nz"
                class="font-weight-bold text-white"
              target="_blank"
              >Firecrest Systems</a
            >.
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="https://tucumcari.co.nz/"
                  class="nav-link text-white"
                  target="_blank"
                  >Tucumcari Website</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://tucumcari.co.nz/contact/"
                  class="nav-link text-white"
                  target="_blank"
                  >Contact Us</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
      </div>
</template>

<script>
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
  name: "Signin",
  components: {
    MaterialInput,
    MaterialButton,
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>
