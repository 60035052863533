<template>
  <div
    class="pt-3 bg-white multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="mt-4 row text-start">
        <h4>Add Supporting files</h4>
        <div class="col-12 mb-4">
          <label class="form-control mb-0">Upload files</label>
          <div
            id="addFiles"
            action="/file-upload"
            class="form-control border dropzone"
          ></div>
        </div>
      </div>
      <div class="mt-4 row text-start">
        <h4>Choose apporvers, or approval group</h4>
        <div class="col-12">
                <div class="d-block">
                <select id="people" ref="skills" multiple class="form-control" name="Status" >
                    <option value="">No one Selected</option>
                    <option value="Gary Cat" >Gary Cat</option>
                    <option value="Safety Group">Safety Group</option>
                </select></div>
        </div>
      </div>

      <div class="mt-4 button-row d-flex">
        <button
          class="mb-0 btn bg-gradient-light js-btn-prev"
          type="button"
          title="Prev"
          @click="$parent.prevStep"
        >
          Prev
        </button>
        <div class="text-end ms-auto">
          <button 
            class="mb-0 btn bg-gradient-primary me-4"
            type="button"
            title="Send"
            @click="$router.push('/permit/123456')"
          >
            Save as draft
          </button>
          <button
            class="mb-0 btn bg-gradient-dark"
            type="button"
            title="Send"
            @click="$router.push('/permit/123456')"
          >
            Save
          </button></div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from "dropzone";
import Choices from "choices.js";

export default {
  name: "Final",
  components: {
  },
  
  data() {
    return {
    };
  },
  mounted() {
    let myDropzone = new Dropzone("#addFiles");
    myDropzone.on("addedfile", (file) => {
      console.log(`File added: ${file.name}`);
    });
    
    
    if (document.getElementById("people")) {
      var skills = document.getElementById("people");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }
  },
  methods: {
  getChoices(id) {
    if (document.getElementById(id)) {
      var element = document.getElementById(id);
      return new Choices(element, {
        searchEnabled: true,
        allowHTML: true,
      });
    }
  },
  },
};
</script>
