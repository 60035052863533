<template>
  <div class="py-4 container-fluid">
      
    <div class="row">
      <div class="col-6">
      <h4 class="m-3">Works Templates</h4> </div>
      <div class="col-6 text-end">
              <router-link to="/edit-template">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Create New Template
                  </MaterialButton></router-link>
                </div>
            <div class="mt-1 mb-1">
    <button class="btn" @click="showActive">Active Templates</button>
    <button class="btn" @click="showSiteWorks">Site works Templates</button>
    <button class="btn" @click="showPermits">Permit Templates</button>
    <button class="btn" @click="showArchived">Archived Templates</button>
    </div>
        <div class="card">
          <div class="card-body">
          <TemplatesTable ref="templatesTable" :list="templateList" />
      </div>
          </div>
        </div>
    </div>
</template>

<script>
import TemplatesTable from "@/components/tables/TemplatesTable.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";

export default {
  name: "Templates",
  components: {
    TemplatesTable,MaterialButton
  },
  data() {
    return {
      templateList: [
    {
        code: "BUNK",
        template: "Bunkering",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
      code: "CONS",
        template: "Confined spaces",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
      code: "CORR",
        template: "Corridor Access",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
      code: "CRU",
        template: "Cruise Activity",
        type: "Site Work",
        status: "Active",
        id:"1234"
    },
    {
      code: "INS",
        template: "Inspection",
        type: "Site Work",
        status: "Active",
        id:"1234"
    },
    {
      code: "LIFT",
        template: "Crane and Heavy lifts",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
      code: "DIVE",
        template: "Dive Ops",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
      code: "DRON",
        template:"Drone Ops",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
      code: "EVAC",
        template: "Evacuations",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
      code: "FUMG",
        template: "Fumigation",
        type: "Permit",
        status: "Archived",
        id:"1234"
    },
    {
      code: "DIGW",
        template: "Ground Penetrating works",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
      code: "HOTW",
        template: "Hotworks",
        type: "Permit",
        status: "Archived",
        id:"1234"
    },
    {
      code: "NAVW",
        template: "Nav Warnings",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
      code: "WATH",
        template: "Working at Height",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
        code: "OTHR",
        template: "Other Permit",
        type: "Permit",
        status: "Active",
        id:"1234"
    },
    {
        code: "CP",
        template: "Capital Project",
        type: "Site Work",
        status: "Active",
        id:"1234"
    },         
    {
      code: "RM",
        template:"Repairs / Maintenance",
        type: "Site Work",
        status: "Active",
        id:"1234"
    },         
    {
      code: "TMP",
        template: "Traffic Management Plan",
        type: "Site Work",
        status: "Active",
        id:"1234"
    },    
]
    };
  },
  mounted() {
    setNavPills();
  },
  methods: {
    showActive() {
      this.$refs.templatesTable.showActive();
    },
    showSiteWorks() {
      this.$refs.templatesTable.showSiteWorks();
    },
    showPermits() {
      this.$refs.templatesTable.showPermits();
    },
    showArchived() {
      this.$refs.templatesTable.showArchived();
    },
    showAll() {
      this.$refs.templatesTable.showAll();
    },
  },
};
</script>
