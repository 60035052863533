<template>
  <div
    class="pt-3 bg-white multisteps-form__panel js-active position-relative"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="mt-3 row text-start">
      <h4>Type</h4>
        <div class="mt-4 col-12 d-flex">
          <material-radio id="hazard" name="radio" checked>Hazard</material-radio>
          <material-radio id="incident" name="radio">Incident</material-radio>
        </div>
      <h4>Details</h4>
          <div class="d-block mb-4">
            <material-input
              id="title"
              variant="dynamic"
              label="Title"
            />
          </div>
          <div class="d-block mb-4">
            <material-input
              id="applicant"
              variant="dynamic"
              label="Applicant"
            />
          </div>
          <div class="d-block mb-4">
                <label class="ms-0">Related To</label>
                <select id="safety" class="form-control" name="Status" >
                    <option value="" selected>None selected</option>
                    <option value="Dock not draining">Dock not draining</option>
                    <option value="Flapping banner not secure">Flapping banner not secure</option>
                    <option value="Collision">Collision</option>
                    <option value="Malfuctions">Malfuctions</option>
                </select>
          </div>
          <div class="d-block mb-4">
          <label class="mt-2 ms-0">Description</label>
                <material-textarea
                id="description"
                variant="static" class="mt-2 ms-0"></material-textarea>
          </div>
          
      </div>
      <div class="mt-4 button-row d-flex">
        <button
          class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="$parent.nextStep"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialRadio from "@/components/materialKit/MaterialRadio.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
import Choices from "choices.js";

export default {
  name: "Start",
  components: { 
    MaterialInput,
    MaterialRadio,
    MaterialTextarea },
  data() {
    return {
      isTemplate: false,
      isPreset: false,
    };
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);

    this.getChoices("safety");
    this.getChoices("preset");
    
    if (document.getElementById("people")) {
      var skills = document.getElementById("people");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }
  },
  methods: {
  getChoices(id) {
    if (document.getElementById(id)) {
      var element = document.getElementById(id);
      return new Choices(element, {
        searchEnabled: true,
        allowHTML: true,
      });
    }
  },
        showPreset() {
            this.isTemplate = false;
            this.isPreset = true;
        },
        hideAll() {
            this.isTemplate = false;
            this.isPreset = false;
        }
  },
};
</script>
