<template>
          <div class="table-responsive">
            <table id="contractor" class="table table-flush" >
              <thead class="thead-light">
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Status</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Name</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Company</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Role</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Reports To</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Start</th>
                </tr>
              </thead>
              <tbody>
          <tr v-for="item in sortedList" :key="item.id" :data-id="item.id">
          <td class="text-sm font-weight-normal">
            
            <button
                  class="btn w-100 m-0 px-1 py-0 text-white"
            :class="{
              pending: item.status == 'Contractor',
              waiting: item.status == 'Port Personnel',
              draft: item.status == 'System User',
            }">
            {{ item.status }}
                </button></td>
          <td class="text-sm font-weight-normal">{{ item.name }}</td>
          <td class="text-sm font-weight-normal">{{ item.company }}</td>
          <td class="text-sm font-weight-normal">{{ item.role }}</td>
          <td class="text-sm font-weight-normal">{{ item.reportsTo }}</td>
          <td class="text-sm font-weight-normal">{{ item.startDate }}</td>
        </tr>
              </tbody>
            </table>
          </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import moment from "moment";

export default {
  name: "PermitsTable",
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dataTable: null,
    };
  },
  computed: {
    sortedList() {
      // Sort the list by start date
      return this.list.slice().sort((a, b) => {
        const dateA = moment(a.startDate, "DD/MM/YYYY");
        const dateB = moment(b.startDate, "DD/MM/YYYY");
        return dateB - dateA;
      });
    },
  },
  mounted() {
    this.initDataTable();
    this.showContractor();
  },
  methods: {
    initDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
      }
      this.dataTable = new DataTable("#contractor", {
        searchable: true,
        fixedHeight: true,
      });  
      // Add link to row
      const tableBody = document.querySelector("#contractor tbody");
        const rows = tableBody.querySelectorAll("tr");
        rows.forEach((row) => {
          const id = row.dataset.id;
          row.addEventListener("click", () => {
            console.log('Row clicked', id); // Verify the click event
            this.$router.push(`/person-profile/${id}`);
          });
      });
},
      // view by status
    showContractor() {
      this.initDataTable();
      const tableBody = document.querySelector("#contractor tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Contractor") {
          row.remove();
        }
      });
    },
    showPortPersonnel() {
      this.initDataTable();
      const tableBody = document.querySelector("#contractor tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Port Personnel") {
          row.remove();
        }
      });
    },
    showAll() {
      this.initDataTable();
    },
  },
};
</script>
<style scoped>
tr{
  cursor: pointer;
}
</style>
