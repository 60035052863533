<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0"
      :class="`fixed-start ${sidebarType}`"
  >
    <div class="sidenav-header">
        <div class="mt-4 me-2 float-end d-xl-none " @click="toggleSidebar">
          <button class="btn btn-link text-white fixed-plugin-close-button p-1">
            <i class="material-icons">clear</i>
          </button>
        </div>
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img
          :src="
            sidebarType === 'bg-white' ||
            (sidebarType === 'bg-transparent' && !isDarkMode)
              ? logoDark
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
      </router-link>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/TucumcariLogo-Full-TransaprentBack.png";
import logoDark from "@/assets/img/TucumcariLogo-Full.png";
import { mapMutations, mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
    };
  },
  computed: {
    ...mapState(["sidebarType", "isDarkMode"]),
  },

methods: {
  ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

  toggleSidebar() {
    this.navbarMinimize();
  },
},
};
</script>
