<template>
  <div class="py-4 container-fluid">
      
    <div class="row">
      <div class="col-6">
      <h4 class="m-3">Permits & Site Works</h4> </div>
      <div class="col-6 text-end">
              <router-link to="/active-work" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Active Work (Map View)
                  </MaterialButton></router-link>
              <router-link to="/add-permit">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >New Permit/Site Works
                  </MaterialButton></router-link>
                </div>
            <div class="mt-1 mb-1">
    <button class="btn" @click="showAll">All</button>
    <button class="btn" @click="showWaiting">Permits awaiting my approval</button>
    <button class="btn" @click="showPending">Permits pending approval</button>
    <button class="btn" @click="showUpdated">Updated</button>
    <button class="btn" @click="showCancelled">Cancelled/declined permits</button>
    <button class="btn" @click="showDrafts">Draft Permits</button></div>
        <div class="card">
          <div class="card-body">
    <PermitsTable ref="permitsTable" :list="permitList" />
      </div>
          </div>
        </div>
    </div>
</template>

<script>
import PermitsTable from "@/components/tables/PermitsTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";

export default {
  name: "PermitList",
  components: {
    PermitsTable,MaterialButton
  },
  //data can be replaced with API data
  data() {
    return {
      permitList: [        {
          title: "Fumigation of that place",
          applicant:"Quinn White",
          type:"Fumigation",
          startDate:"24/04/2024",
          endDate:"27/04/2024",
          location:"That Place",
          status:"Updated",
          id: 123456
        },
        {
          title: "Ship weld Hot works",
          applicant:"Rufus Stuff",
          type:"Hot Works",
          startDate:"04/04/2024",
          endDate:"05/04/2024",
          location:"That Place",
          status:"Pending",
          id: 123456
        },
        {
          title: "Confined Space for Log Yard Barrier Install",
          applicant:"Gary Cat",
          type:"Confined Space",
          startDate:"12/04/2024",
          endDate:"13/04/2024",
          location:"Left Area",
          status:"Awaiting Approval",
          id: 123456
        },
        {
          title: "Bunkering of the Bank",
          applicant:"Bob Dobalina",
          type:"Bunkering",
          startDate:"30/04/2024",
          endDate:"31/04/2024",
          location:"The Bank",
          status:"Draft",
          id: 123456
        },
        {
          title: "Inspection of the Boat",
          applicant:"Jimmy Dogface",
          type:"Inspection",
          startDate:"24/04/2024",
          endDate:"25/04/2024",
          location:"The Boat",
          status:"Cancelled",
          id: 123456
        },
      ],
    };
  },
  methods: {
    showCancelled() {
      this.$refs.permitsTable.showCancelled();
    },
    showWaiting() {
      this.$refs.permitsTable.showWaiting();
    },
    showPending() {
      this.$refs.permitsTable.showPending();
    },
    showDrafts() {
      this.$refs.permitsTable.showDrafts();
    },
    showUpdated() {
      this.$refs.permitsTable.showUpdated();
    },
    showAll() {
      this.$refs.permitsTable.showAll();
    },
  },
};
</script>