<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
        
        <div class="table-responsive">
            <table id="datatable-basic" class="table table-flush" >
              <thead class="thead-light">
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Location
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Type
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Date Added
                  </th>
                </tr>
              </thead>
              <tbody>
        <tr v-for="item in safety" :key="item.name">
          <td class="text-sm font-weight-normal">{{ item.location }}</td>
          <td class="text-sm font-weight-normal">{{ item.type }}</td>
          <td class="text-sm font-weight-normal">{{ item.occurDate }}</td>
        </tr>
              </tbody>
            </table>
          </div>
            </div>
          </div>
        </div>
    <div class="col-lg-6 col-sm-12">
      <div class="mt-4">
       <base-map></base-map>
      </div>
    </div>

      </div>


    </div>
</template>

<script>

import baseMap from "@/components/maps/baseMap.vue";
export default {
  name: "LocationAssets",
  components: {
   baseMap,
  },
  data() {
    return {
      safety: [
        {
          occurDate:"24/04/2024",
          type:"Forklist accident",
          location:"Area 52",
          id: 123456
        },
        {
          occurDate:"04/04/2024",
          location:"The left side",
          type: "Hazard",
          id: 123456
        },
        {
          occurDate:"12/04/2024",
          location:"Area 52",
          type: "Incident",
          id: 123456
        },
        {
          occurDate:"30/04/2024",
          location:"The left side",
          type: "Incident",
          id: 123456
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>

