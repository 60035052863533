<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <h4 class="m-3">Traffic Management Plans</h4> 
      </div>
      <div class="col-6 text-end">
              <router-link to="/active-work" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Active Work (Map View)
                  </MaterialButton></router-link>
              <router-link to="/add-permit">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >New Site Works / Permit
                  </MaterialButton></router-link>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-lg-4">
        <div class="card mt-4">
          <div class="card-body pt-0">
        <div class="mt-1 mb-1">
          <button class="btn" @click="showActive">Active Plans</button>
           <button class="btn" @click="showArchived">Archived Plans</button>
        </div>
          <PlansTable ref="plansTable" :list="plansList" />
          </div>
        </div>
      </div>
      <div class="col-lg-8 mt-lg-0 mt-4">
        <div class="card mt-4">
    <div class="row mt-4">
      <div class="col-6">
        <h4 class="m-3">Add Plan</h4> 
      </div>
      <div class="col-6 text-end">
              <button
                class="btn bg-gradient-info mb-0 toast-btn me-2" type="button"
                >Save</button>
              <button
                class="btn bg-gradient-info mb-0 toast-btn me-2" type="button"
                >Save as copy</button>
      </div>
    </div>
            <div class="card-body">
              <form>
            <material-input
              id="title"
              variant="static"
              label="Plan Name"
            />
            <material-input
              id="title"
              variant="static"
              label="Route"
            />
            <h4 class="mt-4">Notes</h4>
              <label class="form-control mb-0">Note</label>
                <material-textarea
                id="notes"
                variant="static" class="ms-0"></material-textarea>
        <h4 class="mt-4">Add Supporting files</h4>
          <label class="form-control mb-0">Upload files</label>
          <div
            id="addFiles"
            action="/file-upload"
            class="form-control border dropzone"
          ></div>
          
      <div class="mt-4">
            <material-input
              id="location"
              variant="dynamic"
              label="Location"
            />
      </div>
      <div class="mt-4">
        <h4>You can select location and time on the map</h4>
        <base-map></base-map>
      </div>
              </form>
            </div>
        </div>
      </div>
    
    </div>
      
  </div>
</template>

<script>
import PlansTable from "@/components/tables/PlansTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
import Dropzone from "dropzone";
import baseMap from "@/components/maps/baseMap.vue";

export default {
  name: "TrafficManagement",
  components: {
    MaterialButton,
    PlansTable,
    MaterialInput,
    baseMap,
    MaterialTextarea
  },
  //data can be replaced with API data
  data() {
    return {
      plansList: [
        {
          plan:"Left Area Plan",
          location:"Area 52",
          status:"Active",
          id: 123456
        },
        {
          plan:"Right Area Plan",
          location:"The left side",
          status:"Active",
          id: 123456
        },
        {
          plan:"Plan for today",
          location:"Area 52",
          status:"Archived",
          id: 123456
        },
        {
          plan:"Test plan",
          location:"The left side",
          status:"Active",
          id: 123456
        },
      ],
    };
  },
  mounted() {
    let myDropzone = new Dropzone("#addFiles");
    myDropzone.on("addedfile", (file) => {
      console.log(`File added: ${file.name}`);
    });
    
  },
  methods: {
    showActive() {
      this.$refs.plansTable.showActive();
    },
    showArchived() {
      this.$refs.plansTable.showArchived();
    },
    showAll() {
      this.$refs.plansTable.showAll();
    },
  },
};
</script>