<template>
  <div class="py-4 container-fluid">
    <div class="row mb-2">
      <div class="col-lg-4">
        <h4>{{ data.inputs.title }}</h4>
      </div>
              <div class="col-lg-8 text-end">
                <button
                  class="btn bg-gradient-primary mb-0 toast-btn me-2"
                  type="button"
                  @click="toggleDisabled"
                >
                  Edit
                </button>
              <!-- Send for review modal button -->
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                data-bs-toggle="modal" data-bs-target="#reviewModal"
                >
                  Send for Review
                </button>
              <!-- Merge incident modal button -->
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                data-bs-toggle="modal" data-bs-target="#mergeModal"
                >
                  Merge
                </button>
              <!-- Close incident modal button -->
                <button
                  class="btn bg-gradient-dark mb-0 toast-btn me-2"
                  type="button"
                data-bs-toggle="modal" data-bs-target="#closeModal"
                >
                  Close
                </button>
          <router-link to="/incidents">
              <MaterialButton
                variant="gradient"
                color="warning"
              >Incident List
              </MaterialButton></router-link>
            </div>
                <!-- Send for review Modal -->
                <div
                  id="reviewModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="reviewModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="reviewModal" class="modal-title">
                          Send for review 
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <form>
                        <label class="mt-4 ms-0">Person or Team to review the incident</label>
                        <select id="reviewer-edit" class="form-control" name="Related" >
                            <option value="Rufus Dogface">Safety Team</option>
                            <option value="Bob Guy" >Bob Guy</option>
                            <option value="The Electrician">Drivers Team</option>
                            <option value="Fun Time">Fun Times</option>
                            <option value="Mary Onthestop" selected>Mary Onthestop</option>
                        </select>
                        </form>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Send
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Merge Modal -->
                <div
                  id="mergeModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="mergeModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="mergeModal" class="modal-title">
                          Merge this incident
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <p>This incident needs to merge with existing incident</p>
                        <form>
                          <material-input
                          id="step" label="Select incident for merge"
                          variant="static" class="mt-4 ms-0" />
                        </form>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Merge
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Close Modal -->
                <div
                  id="closeModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="closeModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="closeModal" class="modal-title">
                          Close Incident
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <p>This incident is now closed, with follow up actions.</p>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Close Incident
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
    </div>
    <div class="row">
  <div class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'active'"
          >
          <span class="ms-1">Summary</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'reports'"
          >
          <span class="ms-1">Reports</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'timeline'"
          >
          <span class="ms-1">Timeline</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'followup'"
          >
          <span class="ms-1">Follow Up Actions</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="false"
          @click="tab = 'extra'"
          >
          <span class="ms-1">Notes & Attachments</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'contacts'"
          >
          <span class="ms-1">Linked Contacts</span></a
        >
      </li>
    </ul>
  </div>

    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
        <div v-show="tab === 'active'" class="p-3 pt-0 card-body mx-4 my-4" >
            <div class="row mt-4">
              <div class="col-12 col-sm-6">
                <material-input
                id="title" label="Title"
                variant="static" class="mt-2 ms-0"
                :value="data.inputs.title"
                :disabled="isDisabled"
                @update:value="(val) => (data.inputs.title = val)" />

                <div v-if="isDisabled">
                <label class="mt-4 ms-0">Location</label>
                  <option class="isDisabled">That Place</option>
                </div>
                <div v-show="!isDisabled">
                <label class="mt-4 ms-0">Location</label>
                <select id="location-edit" class="form-control" name="Location">
                  <option value="That Place" selected="">That Place</option>
                  <option value="Left Area">Left Area</option>
                  <option value="The Bank">The Bank</option>
                </select>
                </div>

                <label class="mt-4 ms-0">Last updated by</label>
                  <option class="disabledLink"><a href="#">{{ data.inputs.updatedBy }}</a></option>

                <div v-if="isDisabled">
                <label class="mt-4 ms-0">Reviewer</label>
                  <option class="disabledLink"><a href="#">{{ data.inputs.reviewer }}</a></option>
                </div>
                <div v-show="!isDisabled">
                <label class="mt-4 ms-0">Reviewer</label>
                <select id="reviewer-edit" class="form-control" name="Related" >
                    <option value="Rufus Dogface">Rufus Dogface - Contractor</option>
                    <option value="Bob Guy" >Bob Guy</option>
                    <option value="The Electrician">The Electrician - Contractor</option>
                    <option value="Fun Time">Fun Times</option>
                    <option value="Mary Onthestop" selected>Mary Onthestop</option>
                    <option value="Plumbers">Plumbers - Contractor</option>
                    <option value="Those helpful guys">Those helpful guys - Contractor</option>
                </select>
                </div>
              </div>

              <div class="col-12 col-sm-6">
                <label class="mt-2 ms-0">Status</label>
                <button
                      class="btn w-100 mb-2 p-0 text-white"
                :class="{
                  draft: data.inputs.status == 'New',
                  review: data.inputs.status == 'In Review',
                  openIcid: data.inputs.status == 'Open Incident',
                  closed: data.inputs.status == 'Closed',
                }">
                {{ data.inputs.status }}
                </button>

                <div>
                <label class="mt-4 ms-0">Last updated</label>
                  <option class="disabledLink">April 24, 2024 10.30AM</option>
                </div>

                <label class="mt-4 ms-0">Incident occured</label>
                <div class="input-group input-group-static">
                  <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  <flatPickr
                    v-model="date"
                    :disabled="isDisabled"
                    class="form-control datetimepicker"
                    :config="{
                      allowInput: true,
                      altInput: true, 
                      enableTime: true,
                      defaultDate: '2024-04-19T12:00:00'
                    }" />
                </div>

                <div>
                <label class="mt-4 ms-0">Reviewed On</label>
                  <option class="disabledLink">Not yet reviewed</option>
                </div>
              </div>
            </div>

            <div class="col-12">
              <label class="mt-2 ms-0">Description</label>
                <material-textarea
                id="description"
                variant="static" class="mt-2 ms-0"
                :value="data.inputs.description" 
                :disabled="isDisabled"
                @update:value="(val) => (data.inputs.description = val)"></material-textarea>
                
                <div v-if="isDisabled">
                <label class="mt-4 ms-0">Associated Hazards</label>
                  <option class="disabledLink"><a href="#">{{ data.inputs.hazards }}</a></option>
                </div>
                <div v-show="!isDisabled">
                <label class="mt-4 ms-0">Associated Hazards</label>
                <select
                  id="hazard-edit"
                  multiple
                  class="form-control"
                  name="hazards"
                >
                  <option value="Pot hole" selected>Pot hole</option>
                  <option value="Unsealed surface">Unsealed surface</option>
                  <option value="Leaking door">Leaking door</option>
                  <option value="Surface water">Surface water</option>
                </select>
              </div>
              
              <div v-if="isDisabled">
                <label class="mt-4 ms-0">Associated Assets</label>
                  <option class="disabledLink"><a href="#">{{ data.inputs.asset }}</a></option>
                </div>
                <div v-show="!isDisabled">
                <label class="mt-4 ms-0">Associated Assets</label>
                <select
                  id="asset-edit"
                  multiple 
                  class="form-control"
                  name="assets"
                >
                  <option value="Forklift" selected>Forklift</option>
                  <option value="Building 2">Building 2</option>
                  <option value="Vehicle">Vehicle</option>
                  <option value="Ship">Ship</option>
                </select>
              </div>
        
          </div>
        </div>
          
      
      <div v-show="tab === 'reports'" class="p-3 pb-0 card-body" >
      <div class="mt-3 row">
        <h4>Reports</h4>
        
        <div v-for="(report, index) in reports" :key="index" class="col-12 mb-4">
          <div class="mt-2 border card-plain border-radius-lg p-4">
                <div class="row">
                  <div class="col-9">
                    <h6 class="text-dark font-weight-bold mb-0">{{ report.title }}</h6>
                    </div>
                  <div class="col-3">
                <button
                      class="btn w-100 mb-2 p-0 text-white"
                :class="{
                  updated: report.fromStatus == 'Merged in',
                  new: report.fromStatus == 'New',
                }">
                {{ report.fromStatus }} 
                </button>
                  </div>
                  <div class="col-6">
                    <p class="text-sm mt-1 mb-0">
                      <b>Reported By: </b> <br>
                      {{ report.reported }} </p>
                    </div>
                  <div class="col-6">
                    <p class="text-sm mt-1 mb-0">
                      <b>Reported:</b> <br>
                      {{ report.when }} </p>
                  </div>
                  <div class="col-12">
                    <p class="text-sm mt-1 mb-0">
                      <b>Description:</b> <br>
                      {{ report.descipt }} </p>
                <hr>
                <p class="text-sm mt-1 mb-0 font-weight-bold">Incident images</p>
                <div v-for="(image, imageIndex) in report.supportImages" :key="imageIndex" class="d-inline">
                  <img :src="(image)" alt="Control Image" width="150px;">
                </div>
                  </div>
                </div>

            </div>
        </div>

      </div>
      </div>
      
      <div v-show="tab === 'timeline'" class="p-3 pb-0 card-body" >
      <div class="mt-3 row">
        <h4>Timeline</h4>
        <timeline-list title="Timeline">
          <TimelineItem
            color="primary"
            :icon="{ component: 'dataset_linked', class: 'text-white text-sm' }"
            title="Follow up actions added"
            date-time="18 DEC 4:54 AM"
            description="Follow up actions added to this icident"
          />
          <TimelineItem
            color="success"
            :icon="{ component: 'done', class: 'text-white text-sm' }"
            title="Incident sent for review"
            date-time="18 April 12:30 PM"
            description="Sent to safety agent to review"
          />
          <TimelineItem
            color="info"
            :icon="{ component: 'flag', class: 'text-white text-sm' }"
            title="Incident updated"
            date-time="14 April 10:30 AM"
            description="Follow up actions and other fields saved"
          />
          <TimelineItem
            color="warning"
            :icon="{ component: 'dataset_linked', class: 'text-white text-sm' }"
            title="New report added"
            date-time="14 April 2:54 PM"
            description="Reported by secondary and added by merge to first incident"
          />
          <TimelineItem
            color="info"
            :icon="{ component: 'flag', class: 'text-white text-sm' }"
            title="Forklist accident first reported"
            date-time="12 April 4:54 PM"
            description="Incident first reported"
          />
        </timeline-list>
      </div>
      </div>
      
      <div v-show="tab === 'followup'" class="p-3 pb-0 card-body" >
        <div class="mt-3 row">
          <h4>Follow up actions</h4>
            <div v-if="!isDisabled" class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                data-bs-toggle="modal" data-bs-target="#actionModal"
                >Add action
                </button>
             </div>
                <!-- Action Modal -->
                <div
                  id="actionModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="actionModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="actionModal" class="modal-title">
                          Add action
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <form>
                        <label class="form-control mb-0">Action</label>
                              <material-textarea
                              id="action"
                              variant="static" class="ms-0"></material-textarea>
                        </form>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Add
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>

              <div class="col-12 mb-4">
                <label class="form-control mb-0">Action</label>
                <material-textarea
                id="description"
                variant="static" class="ms-0"
                :value="data.inputs.action" 
                :disabled="isDisabled"
                @update:value="(val) => (data.inputs.action = val)"></material-textarea>
              </div>
          </div>
        </div>
      
        <div v-show="tab === 'extra'" class="p-3 pb-0 card-body" >
          <div class="mt-3 row">
            <h4>Notes</h4>
            <div class="col-12 mb-4">
              <label class="form-control mb-0">Note</label>
                <material-textarea
                id="notes"
                variant="static" class="ms-0"
                :disabled="isDisabled"></material-textarea>
            </div>
            <h4>Supporting files</h4>
            <div v-for="(incidentFile, index) in incidentFiles" :key="index" class="col-3 ">
              <div class="text-center fileBlock mt-2">
                <a :href="incidentFile.link" target="_blank">
                <span class="material-icons">{{ incidentFile.icon }}</span>
                <p class="text-sm">{{ incidentFile.fileName }}</p>
                </a>
              </div>
          </div>
          
          <div class="col-12 mb-4">
            <label class="form-control mb-0">Upload files</label>
            <div
              id="incidentFiles"
              action="/file-upload"
              class="form-control border dropzone"
            ></div>
          </div>
        </div>
      </div>
      
      <div v-show="tab === 'contacts'" class="p-3 pb-0 card-body" >
        <div class="mt-3 row">
          <h4>Related Contacts</h4>
            <div v-if="!isDisabled" class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                data-bs-toggle="modal" data-bs-target="#contactModal"
                >Add contact
                </button>
             </div>
                <!-- Action Modal -->
                <div
                  id="contactModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="contactModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="contactModal" class="modal-title">
                          Add contact
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <form>
                          <material-input
                          id="name" label="Link to incident"
                          variant="static" class="mt-4 ms-0" />
                          <material-input
                          id="name" label="Main contact"
                          variant="static" class="mt-4 ms-0" />
                          <material-input
                          id="company" label="Company"
                          variant="static" class="mt-4 ms-0" />
                          <material-input
                          id="name" label="Phone"
                          variant="static" class="mt-4 ms-0" />
                          <material-input
                          id="email" label="Emails"
                          variant="static" class="mt-4 ms-0" />
                        </form>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Add
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
          <div class="col-12 mb-4">
          </div>
          <div
          v-for="(incidentContact, index) in incidentContacts" :key="index"
          class="mb-4 border card-plain border-radius-lg p-4">
            <h6 class="text-dark font-weight-bold mb-0">{{ incidentContact.mainContact }}</h6>
            <div class="row">
                <div class="col-6">
                    <p class="text-sm mt-1 mb-0">
                      <b>Company: </b> <br>
                      {{ incidentContact.company }} </p>
                    <p class="text-sm mt-1 mb-0">
                      <b>Related to this incident: </b> <br>
                      {{ incidentContact.linked }} </p>
                </div>
                <div class="col-6">
                    <p class="text-sm mt-1 mb-0">
                      <b>Phone:</b> <br>
                      {{ incidentContact.phone }} </p>
                    <p class="text-sm mt-1 mb-0">
                      <b>Email:</b> <br>
                      {{ incidentContact.email }} </p>
                </div>
            </div>
        </div>
      </div>
    </div>

      <div v-if="!isDisabled" class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                  @click="toggleDisabled"
                >
                  Save
                </button>
            </div>
          </div>
        </div>

      <div class="col-lg-6 col-sm-12">
        <div class="mt-4 border-radius-2">
       <base-map></base-map>
          </div>
        </div>
      </div>
  </div>
</div>
</template>

<script>

import baseMap from "@/components/maps/baseMap.vue";
import Dropzone from "dropzone";
import Choices from "choices.js";
import flatPickr from "vue-flatpickr-component";
import setNavPills from "@/assets/js/nav-pills.js";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import TimelineList from "@/components/cards/TimelineList.vue";
import TimelineItem from "@/components/cards/TimelineItem.vue";
export default {
  name: "Incident",
  components: {
    baseMap,
    MaterialInput,
    MaterialTextarea,
    flatPickr,
    MaterialButton,
    TimelineList, 
    TimelineItem
  },
  data() {
    return {
      tab: "active",
      isDisabled: true,
      data: {
        inputs: {
          title:"Forklift accident",
          location:"Area 52",
          reviewer:"Not Selected",
          updatedBy:"Gary Cat",
          hazards: "Pot hole",
          status:"Open Incident",
          asset:"Forklift",
          description:"Forklift hit a pothole and bounced",
          id: 123456,
          action:"Added pot hole as a hazard, booked to get repaired. Most likely will happen Wednesday",
        },
      },
      
      reports:[
        {
          reported:"Gary Cat",
          when:"24/04/2024 2.30PM",
          fromStatus:"New",
          title:"Pothole and forklift issue",
          descipt:"Watched this happen was not sure if it was reported",
          supportImages: [
            require("@/assets/img/test1.png"),
            require("@/assets/img/test2.png"),
            require("@/assets/img/test3.png"),
          ],
        },
        {
          reported:"Dave Dog",
          when:"24/04/2024 4.30PM",
          fromStatus:"Merged in",
          title:"Forklift on left side",
          descipt:"Forklist got stuck then bounced from pothole, might be broken",
          supportImages: [
            require("@/assets/img/test3.png"),
            require("@/assets/img/test2.png"),
          ],
        },
      ], 

      incidentFiles:[
        { icon: 'picture_as_pdf', fileName: 'PDF-attachment.pdf', link: 'https://tucumcari.co.nz/wp-content/uploads/2023/07/Buoy-2.jpg' },
        { icon: 'image', fileName: 'Image-of-the-work.jpg', link: 'https://tucumcari.co.nz/wp-content/uploads/2023/07/Buoy-2.jpg' },
        { icon: 'image', fileName: 'Image-of-issue.jpg', link: 'https://tucumcari.co.nz/wp-content/uploads/2023/07/Containers-on-wharf.jpg' },
      ],
      incidentContacts:[
        { 
          mainContact: 'Jeff Bat', 
          company: 'Forklift Wheels', 
          phone: '021 123456' ,
          email: 'forklift@test.com',
          linked: 'For Repairs',
        },
        { 
          mainContact: 'Gary Cat', 
          company: 'Port Personal', 
          phone: '021 123456' ,
          email: 'port@test.com',
          linked: 'Reported Incident',
        }
      ],
    };
  },
  mounted() {
    setNavPills();

    this.getChoices("location-edit");
    this.getChoices("reviewer-edit");
    
    if (document.getElementById("asset-edit")) {
      var asset = document.getElementById("asset-edit");
      new Choices(asset, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }
    
    if (document.getElementById("hazard-edit")) {
      var hazard = document.getElementById("hazard-edit");
      new Choices(hazard, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }

let myDropzone = new Dropzone("#incidentFiles");
myDropzone.on("addedfile", (file) => {
  console.log(`File added: ${file.name}`);
});

  },
  methods: {
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: true,
          allowHTML: true,
        });
      }
    },
    toggleDisabled() {
    this.isDisabled = !this.isDisabled;
}
  },
};
</script>
