<template>
  <div class="py-4 container-fluid">
    <div class="row mb-5">
      <div class="col-lg-8 mt-lg-0 mt-4">
        <div class="card mt-4">
          <div class="card-header">
                <h5 class="mb-1 font-weight-bolder">Active roles</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
                <p class="mb-0 font-weight-bold text-sm">Supervisor</p>
                <p class="text-sm">Reports to Manager</p>
            <div class="col-6">
                <p class="text-sm">Add Edit</p>
              <material-checkbox id="checkboxId" checked>Safety Add Edit Update</material-checkbox>
              <material-checkbox id="checkboxId" checked>Permit Add Edit Update</material-checkbox>
                        </div>
                        <div class="col-6">
                <p class="text-sm">Approve</p>
              <material-checkbox id="checkboxId">Safety Approve</material-checkbox>
              <material-checkbox id="checkboxId">Permit Approve</material-checkbox>
                        </div>
              </div>
              <hr>
            <div class="row">
                <p class="mb-0 font-weight-bold text-sm">Manager</p>
                <p class="text-sm">Reports to Admin</p>
            <div class="col-6">
                <p class="text-sm">Add Edit</p>
              <material-checkbox id="checkboxId" checked>Safety Add Edit Update</material-checkbox>
              <material-checkbox id="checkboxId" checked>Permit Add Edit Update</material-checkbox>
                        </div>
                        <div class="col-6">
                <p class="text-sm">Approve</p>
              <material-checkbox id="checkboxId" checked>Safety Approve</material-checkbox>
              <material-checkbox id="checkboxId" checked>Permit Approve</material-checkbox>
                        </div>
              </div>
              <hr>
            <div class="row">
                <p class="mb-0 font-weight-bold text-sm">Admin</p>
                <p class="text-sm">Reports to Super User</p>
            <div class="col-6">
                <p class="text-sm">Add Edit</p>
              <material-checkbox id="checkboxId" checked>Safety Add Edit Update</material-checkbox>
              <material-checkbox id="checkboxId" checked>Permit Add Edit Update</material-checkbox>
                        </div>
                        <div class="col-6">
                <p class="text-sm">Approve</p>
              <material-checkbox id="checkboxId" checked>Safety Approve</material-checkbox>
              <material-checkbox id="checkboxId" checked>Permit Approve</material-checkbox>
                        </div>
              </div>
              <hr>
              </div> 

          </div>
    </div>
    
    <div class="col-lg-4">
        <div class="card mt-4">
            <div class="card-header">
              <h5>Create new role</h5>
            </div>
            <div class="card-body pt-0">
            <div class="row">
                          <material-input
                          id="add-act" label="Role Title"
                          variant="static" class="mt-2 ms-0 mb-4" />
                          <material-input
                          id="add-act" label="Reports to"
                          variant="static" class="mt-2 ms-0 mb-4" />
            <div class="col-6">
                <p class="text-sm">Add Edit</p>
              <material-checkbox id="checkboxId">Safety Add Edit Update</material-checkbox>
              <material-checkbox id="checkboxId">Permit Add Edit Update</material-checkbox>
                        </div>
                        <div class="col-6">
                <p class="text-sm">Approve</p>
              <material-checkbox id="checkboxId">Safety Approve</material-checkbox>
              <material-checkbox id="checkboxId">Permit Approve</material-checkbox>
                        </div>
                      </div>
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                >
                  Save
                </button>
            </div>
              </div> 



    </div>
    </div>

  </div>
</template>

<script>

import MaterialCheckbox from "@/components/materialKit/MaterialCheckbox.vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
export default {
  name: "Profile",
  components: {
    MaterialCheckbox,MaterialInput
  },
};
</script>
