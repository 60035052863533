import { createRouter, createWebHistory } from "vue-router";

import Index from "../pages/Index.vue";
import Reports from "../pages/Reports.vue";
import SignIn from "../pages/Auth/SignIn.vue";
import Reset from "../pages/Auth/Reset.vue";

import ActiveWork from "../pages/ActiveWork/Active.vue";

import Hazards from "../pages/Safety/Hazards.vue";
import Hazard from "../pages/Safety/Hazard.vue";
import Incidents from "../pages/Safety/Incidents.vue";
import Incident from "../pages/Safety/Incident.vue";
import ReportSafety from "../pages/Safety/safety-wizard/Report.vue";

import WorksList from "../pages/Permits/WorksList.vue";
import AddPermit from "../pages/Permits/permit-wizard/AddPermit.vue";
import Permit from "../pages/Permits/Permit.vue";
import TrafficManagement from "../pages/Permits/TrafficManagement.vue";

import AssetManagement from "../pages/Assets/AssetManagement.vue";
import AddAsset from "../pages/Assets/AddAsset.vue";
import Asset from "../pages/Assets/Asset.vue";
import StoredLocations from "../pages/Assets/StoredLocations.vue";

import RoleManager from "../pages/People/RoleManager.vue";
import Users from "../pages/People/Users.vue";
import Contractors from "../pages/People/Contractors.vue";
import Personnel from "../pages/People/Personnel.vue";
import PersonProfile from "../pages/People/PersonProfile.vue";
import AddPeople from "../pages/People/AddPeople.vue";


import Profile from "../pages/Settings/Profile.vue";
import Templates from "../pages/Settings/Templates.vue";
import EditTemplates from "../pages/Settings/template-wizard/EditTemplate.vue";
import GlobalSettings from "../pages/Settings/GlobalSettings.vue";


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Dashboard",
    component: Index,
    meta: {
      title: "Port Mapping",
    },
  },
  {
    path: "/active-work",
    name: "Active Work",
    component: ActiveWork,
    meta: {
      title: "Active Work",
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      title: "Reports",
    },
  },
  {
    path: "/hazards",
    name: "Hazards",
    component: Hazards,
    meta: {
      title: "Hazards",
    },
  },
  {
    path: "/hazard/:id",
    name: "Hazard",
    component: Hazard,
    meta: {
      title: "Hazard",
    },
  },
  {
    path: "/incidents",
    name: "Incidents",
    component: Incidents,
    meta: {
      title: "Incidents",
    },
  },
  {
    path: "/incident/:id",
    name: "Incident",
    component: Incident,
    meta: {
      title: "Incident",
    },
  },
  {
    path: "/report-issue",
    name: "Report Safety Issue",
    component: ReportSafety,
    meta: {
      title: "Report Safety Issue",
    },
  },
  {
    path: "/works-list",
    name: "Works List",
    component: WorksList,
    meta: {
      title: "Works List",
    },
  },
  {
    path: "/add-permit",
    name: "Add Permit",
    component: AddPermit,
    meta: {
      title: "Add Permit",
    },
  },
  {
    path: "/permit/:id",
    name: "Permit",
    component: Permit,
    meta: {
      title: "Permit",
    },
  },
  {
    path: "/templates",
    name: "Templates",
    component: Templates,
    meta: {
      title: "Templates",
    },
  },
  {
    path: "/edit-template",
    name: "Edit Templates",
    component: EditTemplates,
    meta: {
      title: "Edit Templates",
    },
  },
  {
    path: "/traffic-management",
    name: "Traffic Management",
    component: TrafficManagement,
    meta: {
      title: "Traffic Management Plan",
    },
  },
  {
    path: "/asset-management",
    name: "Asset Management",
    component: AssetManagement,
    meta: {
      title: "Asset Management",
    },
  },
  {
    path: "/add-asset",
    name: "Add Asset",
    component: AddAsset,
    meta: {
      title: "Add Asset",
    },
  },
  {
    path: "/asset/:id",
    name: "Asset",
    component: Asset,
    meta: {
      title: "Asset Information",
    },
  },
  {
    path: "/stored-locations",
    name: "Stored Locations",
    component: StoredLocations,
    meta: {
      title: "Stored Locations",
    },
  },
  {
    path: "/role-manager",
    name: "Role Manager",
    component: RoleManager,
    meta: {
      title: "Role Manager",
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      title: "Users",
    },
  },
  {
    path: "/add-people",
    name: "Add People",
    component: AddPeople,
    meta: {
      title: "Add People",
    },
  },
  {
    path: "/person-profile/:id",
    name: "Person Profile",
    component: PersonProfile,
    meta: {
      title: "Person Profile",
    },
  },
  {
    path: "/personnel",
    name: "Personnel",
    component: Personnel,
    meta: {
      title: "Personnel",
    },
  },
  {
    path: "/contractors",
    name: "Contractors",
    component: Contractors,
    meta: {
      title: "Contractors",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: "My settings",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: GlobalSettings,
    meta: {
      title: "Global Settings",
    },
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
    meta: {
      title: "Sign In",
    },
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
    meta: {
      title: "Reset Password",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to) => {
  document.title = to.meta?.title ?? "Dashboard";
});
export default router;
