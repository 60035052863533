<template>
  <div class="table-responsive">
    <table id="plans" class="table table-flush" >
      <thead class="thead-light">
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >Plan</th>
          <th
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >Location</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >Status</th>
        </tr>
      </thead>
      <tbody>
  <tr v-for="item in list" :key="item.id" :data-id="item.id">
  <td class="text-sm font-weight-normal">{{ item.plan }}</td>
  <td class="text-sm font-weight-normal">{{ item.location }}</td>
  <td class="text-sm font-weight-normal">
    <button
          class="btn w-100 m-0 px-1 py-0 text-white"
    :class="{
      new: item.status == 'Active',
      cancelled: item.status == 'Archived',
    }">
    {{ item.status }}
        </button></td>
</tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";

export default {
name: "PlansTable",
props: {
list: {
type: Array,
required: true,
},
},
data() {
return {
dataTable: null,
};
},
mounted() {
this.initDataTable();
this.showActive();
},
methods: {
initDataTable() {
if (this.dataTable) {
this.dataTable.destroy();
}
this.dataTable = new DataTable("#plans", {
searchable: true,
paging: false
});  
// Add link to row
const tableBody = document.querySelector("#plans tbody");
const rows = tableBody.querySelectorAll("tr");
rows.forEach((row) => {
  row.addEventListener("click", () => {
    window.location.href = `/traffic-management`;
  });
});
},
showActive() {
this.initDataTable();
const tableBody = document.querySelector("#plans tbody");
const rows = tableBody.querySelectorAll("tr");
rows.forEach((row) => {
const statusCell = row.querySelector("td:nth-child(3)");
if (statusCell && statusCell.textContent.trim() !== "Active") {
  row.remove();
}
});
},
showArchived() {
this.initDataTable();
const tableBody = document.querySelector("#plans tbody");
const rows = tableBody.querySelectorAll("tr");
rows.forEach((row) => {
const statusCell = row.querySelector("td:nth-child(3)");
if (statusCell && statusCell.textContent.trim() !== "Archived") {
  row.remove();
}
});
},
showAll() {
this.initDataTable();
},
},
};
</script>
<style scoped>
tr{
cursor: pointer;
}
</style>
