<template>
  <div id="top" class="py-4 h-100 container-fluid">
    <div class="row">
        <h4>Add People
        </h4>
      <div class="col-8 mx-auto">
        <div class="card mt-4">
          <div class="card-body p-3">
                
            <div class="row mt-4">
              <div class="col-12 col-sm-8">
                <material-input
                id="title" label="Name"
                variant="static" class="mt-2 ms-0" />

                <material-input
                id="title" label="Company"
                variant="static" class="mt-4 ms-0"/>

                <material-input
                id="title" label="Work Role"
                variant="static" class="mt-4 ms-0" />

                <material-input
                id="title" label="Reports to"
                variant="static" class="mt-4 ms-0" />

                <material-input
                id="title" label="Licences"
                variant="static" class="mt-4 ms-0" />

                <material-input
                id="title" label="Qualifications"
                variant="static" class="mt-4 ms-0" />

              <label class="mt-2 ms-0">Description</label>
                <material-textarea
                id="description"
                variant="static" class="mt-2 ms-0"></material-textarea>

              </div>

              <div class="col-12 col-sm-4 p-3">
                <h5 class="text-sm">Type</h5>
                <div class="p-3">
              <material-checkbox id="checkboxId">Contractor</material-checkbox>
              <material-checkbox id="checkboxId">Port Personnel</material-checkbox>
            </div>
                <h5 class="text-sm">Roles</h5>
                <div class="p-3">
              <material-checkbox id="checkboxId">Safety Role</material-checkbox>
              <material-checkbox id="checkboxId">Supervisor Role</material-checkbox>
              <material-checkbox id="checkboxId">Admin Role</material-checkbox>
                <h5 class="m-3">Contact</h5>
                <material-input
                id="title" label="Email"
                variant="static" class="mt-4 ms-0" />
                <material-input
                id="title" label="Phone"
                variant="static" class="mt-4 ms-0" />
              </div>
              </div>
            </div>
            <div class="row mt-4">
              
      <div class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                  @click="$router.push('/person-profile/123456')"
                >
                  Save
                </button>
            </div>
            </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
import MaterialCheckbox from "@/components/materialKit/MaterialCheckbox.vue";
export default {
  name: "AddAsset",
  components: {
    MaterialInput,
    MaterialTextarea,MaterialCheckbox
  },  
  data() {
    return {
      tab: "active",
    };
  },
};
</script>
