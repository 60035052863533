<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-4">
        <h4 class="m-3">Active Work</h4> </div>
      <div class="col-8 text-end">
        <router-link to="/works-list" class="me-2">
           <MaterialButton
            variant="gradient"
            color="info"
            >Permits & Site Works List
            </MaterialButton></router-link>
          <router-link to="/add-permit" class="me-2">
            <MaterialButton
            variant="gradient"
            color="info"
            >New Permit/Site Works
            </MaterialButton></router-link>
          <router-link to="/hazards" class="me-2">
              <MaterialButton
                variant="gradient"
                color="warning"
              >Hazards List
              </MaterialButton></router-link>
          <router-link to="/incidents">
              <MaterialButton
                variant="gradient"
                color="danger"
              >Incident List
              </MaterialButton></router-link>
        </div>
      <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1 active active"
              data-bs-toggle="tab"
              href="javascript:;"
              role="tab"
              aria-selected="true"
              @click="tab = 'active'"
              ><i class="material-icons text-lg position-relative">my_location</i>
              <span class="ms-1">Active work/permits</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1 active active"
              data-bs-toggle="tab"
              href="javascript:;"
              role="tab"
              aria-selected="true"
              @click="tab = 'approval'"
              ><i class="material-icons text-lg position-relative">check_circle</i>
              <span class="ms-1">Permits needing approval</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              data-bs-toggle="tab"
              href="javascript:;"
              role="tab"
              aria-selected="false"
              @click="tab = 'recent'"
              ><i class="material-icons text-lg position-relative">timer</i>
              <span class="ms-1">Recent incidents</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              data-bs-toggle="tab"
              href="javascript:;"
              role="tab"
              aria-selected="false"
              @click="tab = 'open'"
              ><i class="material-icons text-lg position-relative">list_alt</i>
              <span class="ms-1">Open incidents</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              data-bs-toggle="tab"
              href="javascript:;"
              role="tab"
              aria-selected="false"
              @click="tab = 'hazards'"
              ><i class="material-icons text-lg position-relative">support</i>
              <span class="ms-1">All hazards</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              data-bs-toggle="tab"
              href="javascript:;"
              role="tab"
              aria-selected="false"
              @click="tab = 'managed'"
              ><i class="material-icons text-lg position-relative">analytics</i>
              <span class="ms-1">Managed hazards</span></a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="card mt-4">
    <div class="row">
      <div class="p-3 pb-0 card-body">
        <div v-if="tab === 'active'" class="col-12 px-3" >
        <p>Base map</p>
        </div>
        <div v-else-if="tab === 'approval'" class="col-12 px-3" >
        <p>Map requires filtering for approval</p>
        </div>
        <div v-else-if="tab === 'recent'" class="col-12 px-3" >
        <p>Map requires filtering for recent incidents</p>
        </div>
        <div v-else-if="tab === 'open'" class="col-12 px-3" >
        <p>Map requires filtering for open incidents</p>
        </div>
        <div v-else-if="tab === 'hazards'" class="col-12 px-3" >
        <p>Map requires filtering for hazards</p>
        </div>
        <div v-else-if="tab === 'managed'" class="col-12 px-3" >
        <p>Map requires filtering for users managed hazards</p>
        </div>
        <div class="col-12">
          <active-map></active-map>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import activeMap from "@/components/maps/activeMap.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";

export default {
  name: "ActiveWork",
  components: {
    MaterialButton,
    activeMap,
  },
  data() {
    return {
      tab: "active",
    };
  },
  mounted() {
    setNavPills();
  },
};
</script>