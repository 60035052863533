<template>
  <div class="py-4 container-fluid">
    <div class="row mb-2">
      <div class="col-lg-4">
        <h4>{{ permit.inputs.title }}</h4>
      </div>
              <div class="col-lg-8 text-end">
                <button
                  class="btn bg-gradient-primary mb-0 toast-btn me-2"
                  type="button"
                  @click="toggleDisabled"
                >
                  Edit
                </button>
                
              <!-- Submit for approval modal button -->
              <button
                class="btn bg-gradient-info mb-1 toast-btn me-2" type="button"
                data-bs-toggle="modal" data-bs-target="#submitModal"
                >Submit for approval</button>
              <!-- Approve modal button -->
              <button
                class="btn bg-gradient-info mb-1 toast-btn me-2" type="button"
                data-bs-toggle="modal" data-bs-target="#approvetModal"
                >Approve</button>
                
              <!-- Cancel modal button -->
              <button
                class="btn bg-gradient-dark mb-1 toast-btn me-2" type="button"
                data-bs-toggle="modal" data-bs-target="#cancelModal"
                >Cancel / Decline</button>

              <!-- Clone modal button -->
              <button
                class="btn bg-gradient-info mb-1 toast-btn me-2" type="button"
                data-bs-toggle="modal" data-bs-target="#cloneModal"
                >Clone as preset</button>

              <button
                class="btn bg-gradient-warning mb-1 toast-btn me-2" type="button"
                  @click="$router.push('/works-list')"
                >Works Lists</button>
            </div>

                <!-- Submit for approval Modal -->
                <div
                  id="submitModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="submitModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="submitModal" class="modal-title">
                          Submit for approval
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <form>
                          <material-input
                          id="step" label="Submit to"
                          variant="static" class="mt-4 ms-0" />
                        </form>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Approve Modal -->
                <div
                  id="approvetModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="approvetModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="approvetModal" class="modal-title">
                          Submit for approval
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <p>I understand and approve this permit</p>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Approve
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Cancel Modal -->
                <div
                  id="cancelModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="cancelModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="cancelModal" class="modal-title">
                          Cancel / Decline Permit
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <p>This permit is not approved</p>
                        <label>Reason</label>
                        <material-textarea
                        id="cancelled"
                        variant="static" class="mt-2 ms-0"
                        ></material-textarea>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Decline
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Clone Modal -->
                <div
                  id="cloneModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="cloneModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="cloneModal" class="modal-title">
                          Clone this premiit / Site Works
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <p>Duplicate this permit as a copy</p>
                        <form>
                          <material-input
                          id="title" label="Label"
                          variant="static" class="mt-2 ms-0"
                          :value="permit.inputs.title + ' copy'"  />
                        </form>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Clone
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
    </div>
  <div class="row">
  <div class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'active'"
          >
          <span class="ms-1">Summary</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'activites'"
          >
          <span class="ms-1">Activities & Risk</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'procedure'"
          >
          <span class="ms-1">Procedure</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="false"
          @click="tab = 'extra'"
          >
          <span class="ms-1">Attachments</span></a
        >
      </li>
    </ul>
  </div>
  <div class="col-lg-6 col-sm-12">
    <div class="row">
      <div class="card mt-4">
        <div v-show="tab === 'active'" class="p-3 pt-0 card-body mx-4 my-4" >
          <div class="row mt-2">
            <h4>Permit</h4> 
            <div class="col-12 col-sm-6">
                <material-input
                id="title" label="Project"
                variant="static" class="mt-2 ms-0"
                :value="permit.inputs.title" 
                :disabled="isDisabled"
                @update:value="(val) => (permit.inputs.title = val)" />

                <material-input
                id="applicant" label="Applicant"
                variant="static" class="mt-4 ms-0"
                :value="permit.inputs.applicant"
                :disabled="isDisabled"
                @update:value="(val) => (permit.inputs.applicant = val)" />

                <div v-if="isDisabled">
                <label class="mt-4 ms-0">Conducting Work</label>
                  <option class="disabledLink"><a href="/contractors" target="_blank">Rufus Dogface - Contractor</a></option>
                </div>
                <div v-show="!isDisabled">
                <label class="mt-3 ms-0">Conducting Work</label>
                <select id="people" ref="skills" multiple class="form-control" name="Status" >
                    <option value="Rufus Dogface">Rufus Dogface - Contractor</option>
                    <option value="Bob Guy" >Bob Guy</option>
                    <option value="The Electrician">The Electrician - Contractor</option>
                    <option value="Fun Time">Fun Times</option>
                    <option value="Mary Onthestop">Mary Onthestop</option>
                    <option value="Plumbers">Plumbers - Contractor</option>
                    <option value="Those helpful guys">Those helpful guys - Contractor</option>
                </select>
                </div>

                <material-input
                id="applicant" label="Approver/Supervisor"
                variant="static" class="mt-4 ms-0"
                :value="permit.inputs.approver"
                :disabled="isDisabled"
                @update:value="(val) => (permit.inputs.approver = val)" />

                <div v-if="isDisabled">
                <label class="mt-4 ms-0">Locations</label>
                  <option class="isDisabled">That Place</option>
                </div>
                <div v-show="!isDisabled">
                <label class="mt-3 ms-0">Locations</label>
                <select id="location-edit" ref="skills" multiple class="form-control" name="Locations" >
                  <option value="That Place" selected="">That Place</option>
                  <option value="Left Area">Left Area</option>
                  <option value="The Bank">The Bank</option>
                </select>
                </div>

                <div v-if="isDisabled">
                <label class="mt-4 ms-0">Template</label>
                  <option class="isDisabled">Confined Space</option>
                </div>
                <div v-show="!isDisabled">
                <label class="mt-4 ms-0">Template</label>
                <select id="permitType-edit" class="form-control" name="Status" >
                    <option value="Confined Space" selected>Confined Space</option>
                    <option value="Bunkering">Bunkering</option>
                    <option value="Corridor Access">Corridor Access</option>
                    <option value="Crane and Heavy Lifts">Crane and Heavy Lifts</option>
                    <option value="Dive Ops">Dive Ops</option>
                </select>
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <label class="mt-2 ms-0">Status</label>
                <button
                      class="btn w-100 mb-2 p-0 text-white"
                :class="{
                  pending: permit.inputs.status == 'Pending (to be approved)',
                  waiting: permit.inputs.status == 'Awaiting my approval',
                  draft: permit.inputs.status == 'Draft',
                  cancelled: permit.inputs.status == 'Declined/cancelled',
                  updated: permit.inputs.status == 'Updated',
                }">
                {{ permit.inputs.status }}
                </button>
                
                <div>
                <label class="mt-4 ms-0">Logged</label>
                  <option class="disabledLink"><a href="#">April 4, 2024 10.30AM</a></option>
                </div>
                
                <label class="mt-3 ms-0">From</label>
                <div class="input-group input-group-static">
                  <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  <flatPickr
                    v-model="date"
                    :disabled="isDisabled"
                    class="form-control datetimepicker"
                    :config="{
                      allowInput: true,
                      altInput: true, 
                      enableTime: true,
                      defaultDate: '2024-04-19T12:00:00'
                    }" />
                </div>

                <label class="mt-3 ms-0">To</label>
                <div class="input-group input-group-static">
                  <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  <flatPickr
                    v-model="date"
                    :disabled="isDisabled"
                    class="form-control datetimepicker"
                    :config="{
                      allowInput: true,
                      altInput: true, 
                      enableTime: true,
                      defaultDate: '2024-04-19T12:01:00'
                    }" />
                </div>

                <div>
                <label class="mt-4 ms-0">Approved On</label>
                  <option class="disabledLink">Not yet approved</option>
                </div>

                <div v-if="isDisabled">
                <label class="mt-4 ms-0">Related to</label>
                  <option class="disabledLink"><a href="#">Site Work: Barrier Install</a></option>
                </div>
                <div v-show="!isDisabled">
                <label class="mt-4 ms-0">Type</label>
                <select id="related-edit" class="form-control" name="Related" >
                    <option value="Confined Space" selected>Site Work: Barrier Install</option>
                    <option value="Bunkering">Hot Work: Barrier Install</option>
                    <option value="Corridor Access">Excavation/Ground Penetration: Barrier Install</option>
                </select>
                </div>

            </div>

            <div class="col-12">
                <material-input
                id="applicant" label="Qualifications Required"
                variant="static" class="mt-4 ms-0"
                :value="permit.inputs.qualification"
                :disabled="isDisabled"
                @update:value="(val) => (permit.inputs.qualification = val)" />

                <label class="mt-2 ms-0">Description</label>
                <material-textarea
                id="description"
                variant="static" class="mt-2 ms-0"
                :value="permit.inputs.description" 
                :disabled="isDisabled"
                @update:value="(val) => (permit.inputs.description = val)"></material-textarea>
            </div>
          </div>
    </div>
    
      <div v-show="tab === 'activites'" class="p-3 pb-0 card-body" >
        <div class="row mt-2">
            <h4>Standard Activites</h4>
              <div v-if="!isDisabled" >
                          <material-input
                          id="add-act" label="Add Standard Activity"
                          variant="static" class="mt-2 ms-0 mb-4" />
              </div> 
            <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Work at Heights</material-checkbox>
              <material-checkbox id="checkboxId">Confined Space Entry</material-checkbox>
              <material-checkbox id="checkboxId">Hazardous Substance</material-checkbox>
              <material-checkbox id="checkboxId" checked>Mobile Plant</material-checkbox>
              <material-checkbox id="checkboxId">Roof Access</material-checkbox>
              <material-checkbox id="checkboxId">Power Tools</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Excavation >300mm</material-checkbox>
              <material-checkbox id="checkboxId">Excavation >1.5m</material-checkbox>
              <material-checkbox id="checkboxId">Working with Chemicals</material-checkbox>
              <material-checkbox id="checkboxId">Energised electrical work</material-checkbox>
              <material-checkbox id="checkboxId">Breakwater Access</material-checkbox>
              <material-checkbox id="checkboxId">Workshop Machinery</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Hot Works</material-checkbox>
              <material-checkbox id="checkboxId">Punt</material-checkbox>
              <material-checkbox id="checkboxId">Forklift Cage</material-checkbox>
              <material-checkbox id="checkboxId">Palfinger</material-checkbox>
              <material-checkbox id="checkboxId">Pits</material-checkbox>
              <material-checkbox id="checkboxId">Welding or Gas Cutting</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Pavement Inspection</material-checkbox>
              <material-checkbox id="checkboxId">Building Inspections</material-checkbox>
              <material-checkbox id="checkboxId">Survey Boat Operations</material-checkbox>
              <material-checkbox id="checkboxId">Working with Chemicals</material-checkbox>
              <material-checkbox id="checkboxId">Bollard Removal/Exchange</material-checkbox>
                        </div>
                        <h4>Risks</h4> 
              <div v-if="!isDisabled" >
                          <material-input
                          id="add-act" label="Add Risk"
                          variant="static" class="mt-2 ms-0 mb-4" />
              </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Asbestos Exposure</material-checkbox>
              <material-checkbox id="checkboxId">Body Injury</material-checkbox>
              <material-checkbox id="checkboxId">Eye Damage</material-checkbox>
              <material-checkbox id="checkboxId">Burns</material-checkbox>
              <material-checkbox id="checkboxId">Trips, Slips, Falls </material-checkbox>
              <material-checkbox id="checkboxId">Strain</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId">Chemical Exposure</material-checkbox>
              <material-checkbox id="checkboxId" checked>Collision</material-checkbox>
              <material-checkbox id="checkboxId">Engulfment</material-checkbox>
              <material-checkbox id="checkboxId">Electrocution</material-checkbox>
              <material-checkbox id="checkboxId">Explosion</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3">
              <material-checkbox id="checkboxId" checked>Crush</material-checkbox>
              <material-checkbox id="checkboxId">Sprains and Strains</material-checkbox>
              <material-checkbox id="checkboxId">Hearing Loss</material-checkbox>
              <material-checkbox id="checkboxId">Drowning</material-checkbox>
              <material-checkbox id="checkboxId" checked>Falling Objects</material-checkbox>
                        </div>
                        <div class="col-12 col-sm-3 mb-4">
              <material-checkbox id="checkboxId" checked>Falling from Height</material-checkbox>
              <material-checkbox id="checkboxId">Asphyxiation</material-checkbox>
              <material-checkbox id="checkboxId">Noxious Gases</material-checkbox>
              <material-checkbox id="checkboxId">Fire</material-checkbox>
              <material-checkbox id="checkboxId">Foot Injury</material-checkbox>
              <material-checkbox id="checkboxId" checked>Hand Injury</material-checkbox>
            </div>
        </div>
      </div>
      
      <div v-show="tab === 'procedure'" class="p-3 pb-0 card-body" >
            <div class="col-12 mb-4">
      <div class="mt-3 row">
              <div class="mt-2 mb-4 border card-plain border-radius-lg p-4">
      <div v-if="!isDisabled" class="text-end mb-4">
                <button
                  class="btn mb-0 toast-btn me-2"
                  type="button"
                >
                  Remove
                </button>
            </div>
                <a href="#" target="_blank">Traffic Management Plan for Route 6</a>

              </div>
            </div>
            <div class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                  onclick="window.open('/img/RISK-SCORE-CALCULATOR.png', '_blank')"
                >
                  Check Risk Score Calculator
                </button>
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                  onclick="window.open('/img/Hierarchy-of-Control-Measures.png', '_blank')"
                >
                  Check Hierarchy of Control Measures
                </button>
            </div>
            <p class="text-dark">IF RESIDUAL RISK SCORE IS STILL HIGH OR CRITICAL, A WORK METHOD STATEMENT MUST BE CREATED AND APPROVED BY INFRASTRUCTURE SERVICES MANGER</p>
            <div v-for="(operatingStep, index) in operatingSteps" :key="index" class="col-12 mb-4">
              <div class="mt-2 border card-plain border-radius-lg p-4">
      <div v-if="!isDisabled" class="text-end mb-4">
                <button
                  class="btn mb-0 toast-btn me-2"
                  type="button"
                >
                  Remove step
                </button>
            </div>
                <h6 class="text-dark font-weight-bold mb-0">Step: {{ operatingStep.step }}</h6>
                <div class="row">
                  <div class="col-6">
                    <p class="text-sm mt-1 mb-0">
                      <b>Hazards:</b> <br>
                      {{ operatingStep.hazrd }} </p></div>
                  <div class="col-6">
                    <p class="text-sm mt-1 mb-0">
                    <b>Inherent Risk Score:</b> {{ operatingStep.inheritRisk }}<br>
                    <b>Residual Risk Score: <span class="text-success">{{ operatingStep.residualRisk }}</span></b><br>
                      <b>Action By:</b> {{ operatingStep.group }}</p>
                  </div>
                </div>
                <hr>
                <p class="text-sm mt-1 mb-0 font-weight-bold">Control Methods & Monitoring:</p>
                  <ul class="text-sm mt-1 mb-0" >
                      <li v-for="(control, controlIndex) in operatingStep.control" :key="controlIndex">
                          {{ control }}
                      </li>
                  </ul>
            </div>
        </div>
      <div v-if="!isDisabled" class="text-end mb-4">
          <!-- Button add step modal -->
          <button
            class="btn bg-gradient-info mb-0 toast-btn me-2" type="button"
            data-bs-toggle="modal" data-bs-target="#addStepModal"
            >Add new step</button>
            <!-- Modal -->
            <div
              id="addStepModal"
              class="modal fade"
              tabindex="-1"
              aria-labelledby="addStepModal"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 id="addStepModal" class="modal-title">
                      Add Procedure Step
                    </h5>
                    <MaterialButton
                      color="none"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                    </MaterialButton>
                  </div>
                  <div class="modal-body">
                    <form class="row">
                    <div class="col-12">
                      <material-input
                      id="step" label="Step"
                      variant="static" class="mt-2 ms-0" />
                    </div>
                    <div class="col-12 text-start">
                      <label class="mt-4 ms-0">Hazards</label>
                      <material-textarea
                      id="description"
                      variant="static" class="mt-0 ms-0"></material-textarea>
                    </div>
                    <div class="col-12 col-sm-6">
                      <material-input
                      id="riskScore1" label="Inherent Risk Score"
                      variant="static" class="mt-2 ms-0" />
                    </div>
                    <div class="col-12 col-sm-6">
                      <material-input
                      id="riskScore2" label="Residual Risk Score"
                      variant="static" class="mt-2 ms-0" />
                    </div>
                    <div class="col-12 text-start">
                      <label class="mt-4 ms-0">Control Methods & Monitoring</label>
                      <material-textarea
                      id="description"
                      variant="static" class="mt-0 ms-0"></material-textarea>
                    </div>
                    </form>
                  </div>
                  <div class="modal-footer justify-content-between">
                    <MaterialButton
                      variant="gradient"
                      color="dark"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </MaterialButton>
                    <MaterialButton
                      variant="gradient"
                      color="info"
                      class="mb-0"
                      data-bs-dismiss="modal"
                    >
                      Add Step
                    </MaterialButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      </div>
      
      <div v-show="tab === 'extra'" class="p-3 pb-0 card-body" >
      <div class="mt-3 row">
        <h4>Supporting files</h4>
        <div v-for="(permitFile, index) in permitFiles" :key="index" class="col-3 ">
          <div class="text-center fileBlock mt-2">
            <a :href="permitFile.link" target="_blank">
            <span class="material-icons">{{ permitFile.icon }}</span>
            <p class="text-sm">{{ permitFile.fileName }}</p>
          </a>
          </div>
        </div>
        <div class="col-12 mb-4">
          <label class="form-control mb-0">Upload files</label>
          <div
            id="permitFiles"
            action="/file-upload"
            class="form-control border dropzone"
          ></div>
        </div>
      </div>
      </div>
      
      <div v-if="!isDisabled" class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                  @click="toggleDisabled"
                >
                  Save draft
                </button>
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                  @click="toggleDisabled"
                >
                  Save approved (update)
                </button>
            </div>

      </div>
      </div>
    </div>
    <div class="col-lg-6 col-sm-12">
      <div class="mt-4">
       <base-map></base-map>
      </div>
    </div>
  </div>
  </div>
</template>


<script>
import baseMap from "@/components/maps/baseMap.vue";
import Dropzone from "dropzone";
import Choices from "choices.js";
import flatPickr from "vue-flatpickr-component";
import setNavPills from "@/assets/js/nav-pills.js";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
import MaterialCheckbox from "@/components/materialKit/MaterialCheckbox.vue";

export default {
  name: "Permit",
  components: {
   baseMap,
    MaterialInput,
    flatPickr,
    MaterialTextarea,
    MaterialCheckbox,
    MaterialButton
  },
  
  data() {
    return {
      tab: "active",
      isDisabled: true,
      permit: {
        inputs: {
          title:"Confined Space for Log Yard Barrier Install",
          applicant:"Quinn White",
          approver:"Gary Cat",
          type:"Fumigation",
          startDate:"24/04/2024",
          endDate:"27/04/2024",
          location:"That Place",
          status:"Draft",
          description:"Installing 5m posts onto the concrete log yard barriers",
          qualification:"Forklist licence",
          id: 123456
        },
      },
      permitFiles:[
        { icon: 'picture_as_pdf', fileName: 'PDF-attachment.pdf', link: 'https://tucumcari.co.nz/wp-content/uploads/2023/07/Buoy-2.jpg' },
        { icon: 'image', fileName: 'Image-of-the-work.jpg', link: 'https://tucumcari.co.nz/wp-content/uploads/2023/07/Buoy-2.jpg' },
        { icon: 'image', fileName: 'Image-of-issue.jpg', link: 'https://tucumcari.co.nz/wp-content/uploads/2023/07/Containers-on-wharf.jpg' },
      ],
      operatingSteps:[
        {
          step:"Is the Operator Qualified to Operate the Forklift for lifting and placing a load",
          hazrd:"Unqualified Operator",
          inheritRisk:"High",
          residualRisk:"Low",
          group :"All",
          control: [
              "Operator must hold a Class 4 HT Licence.",
              "Operator must hold NZQA Unit Standard 16617",
              "Operator has sufficient training for operating the Napier Port Forklift.",
              "Operator PPE – Hard hats, safety glasses and safety footwear",
              "Dogman PPE – Hard hats, safety glasses, safety footwear and leather gloves",
          ],
        },
        {
          step:"Forklift Pre Inspection",
          hazrd:"Identify machine malfunctioning",
          inheritRisk:"Low",
          residualRisk:"Low",
          group :"All",
          control: [
              "Review weather forecast.",
              "Assess wind speed and impact on the job.",
              "If heavy rain consider impacts.",
          ],
        },
        {
          step:"Environmental Influences",
          hazrd:"High winds and heavy rain",
          inheritRisk:"Low",
          residualRisk:"Low",
          group :"All",
          control: [
              "Ensure that log book has been completed.",
              "View that Fork lift annual certification date to ensure not expired.",
              "Visually inspect forklift",
              "Take care when operating. Always check gauges, and listen for abnormal noises and abnormal operating functions when using the forklift",
          ],
        }
      ]
    };
  },
  mounted() {
    setNavPills();
    this.getChoices("permitType-edit");
    this.getChoices("permitType");
    this.getChoices("location-edit");
    this.getChoices("related-edit");

    if (document.getElementById("people")) {
      var skills = document.getElementById("people");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }

    let myDropzone = new Dropzone("#permitFiles");
    myDropzone.on("addedfile", (file) => {
      console.log(`File added: ${file.name}`);
    });
    

  },
methods: {
    getChoices(id) {
        if (document.getElementById(id)) {
            var element = document.getElementById(id);
            return new Choices(element, {
                searchEnabled: true,
                allowHTML: true,
            });
        }
    },
    toggleDisabled() {
    this.isDisabled = !this.isDisabled;
}
  },
};
</script>
<style scoped>
.fileBlock{
  border-radius: 10px;
  border:solid 1px #ebebeb;
  padding:10px;
}
</style>
