<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
      <h4 class="m-3">Assets</h4> </div>
      <div class="col-6 text-end">
              <router-link to="/active-work" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Assets Locations (Map View)
                  </MaterialButton></router-link>
              <router-link to="/add-asset" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Add Asset
                  </MaterialButton></router-link>
                </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Assets with an upcoming expiry</h5> 
              </div>
              <div class="col-lg-3 col-sm-12 text-end"> 
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning"
                  @click="showExpire"
                  ><h5 class="text-white mt-3">8</h5>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Assets linked to incidents</h5> 
              </div>
              <div class="col-lg-3 col-sm-12 text-end"> 
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning"
                  @click="showLinked"
                  ><h5 class="text-white mt-3">12</h5>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      
    <div class="row">
      <div class="p-3 pb-0 card-body">
    <button class="btn " @click="showAll">All</button>
    <button class="btn " @click="showActive">Active</button>
    <button class="btn" @click="showExpire">Upcoming Expiry</button>
    <button class="btn" @click="showLinked">Linked to incidents</button>
    <button class="btn" @click="showArchived">Archived</button>
  </div>
        <div class="card">
          <div class="card-body">
    <AssetsTable ref="assetsTable" :list="assetsList" />
      </div>
          </div>
      </div>
      </div>


    </div>
</template>

<script>

import AssetsTable from "@/components/tables/AssetsTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
export default {
  name: "Hazards",
  components: {
    AssetsTable,MaterialButton,
  },
  //data can be replaced with API data
  data() {
    return {
      filterType: 'all',
      assetsList: [
        {
          title:"Forklift",
          expiry:"24/04/2024",
          location:"Area 52",
          incidents: "Linked",
          status:"Active",
          id: 123456
        },
        {
          title:"Crane",
          expiry:"24/04/2024",
          location:"The left side",
          incidents:"",
          status:"Active",
          id: 123456
        },
        {
          title:"Building",
          expiry:"",
          location:"Area 52",
          incidents:"Linked",
          status:"Archived",
          id: 123456
        },
        {
          title:"Loader",
          expiry:"",
          location:"Area 52",
          incidents:"",
          status:"Archived",
          id: 123456
        },
        {
          title:"Bollards",
          expiry:"",
          location:"The left side",
          incidents:"Linked",
          status:"Active",
          id: 123456
        },
        {
          title:"Truck",
          expiry:"24/04/2024",
          location:"The left side",
          incidents:"",
          status:"Active",
          id: 123456
        },
      ],
    };
  },
  methods: {
    showLinked() {
      this.$refs.assetsTable.showLinked();
    },
    showActive() {
      this.$refs.assetsTable.showActive();
    },
    showExpire() {
      this.$refs.assetsTable.showExpire();
    },
    showArchived() {
      this.$refs.assetsTable.showArchived();
    },
    showAll() {
      this.$refs.assetsTable.showAll();
    },
  },
};
</script>

<style scoped>
tr, .bg-gradient-info, .icon-lg{
  cursor: pointer;
}
</style>

