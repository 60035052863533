<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
      <h4 class="m-3">Site Hazards</h4> </div>
      <div class="col-6 text-end">
              <router-link to="/active-work" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Recent Hazards (Map View)
                  </MaterialButton></router-link>
              <router-link to="/report-issue" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Report Hazard
                  </MaterialButton></router-link>
          <router-link to="/incidents">
              <MaterialButton
                variant="gradient"
                color="warning"
              >Incident List
              </MaterialButton></router-link>
                </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">New hazards reported</h5> 
              </div>
              <div class="col-lg-3 col-sm-12 text-end"> 
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-danger"
                  @click="showNew"
                  ><h5 class="text-white mt-3">8</h5>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Open hazards linked to incidents</h5> 
              </div>
              <div class="col-lg-3 col-sm-12 text-end"> 
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-danger"
                  @click="showLinked"
                  ><h5 class="text-white mt-3">12</h5>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      
    <div class="row">
      <div class="p-3 pb-0 card-body">
    <button class="btn " @click="showAll">All</button>
    <button class="btn" @click="showNew">New Hazards</button>
    <button class="btn" @click="showOpen">Open Hazards</button>
    <button class="btn" @click="showLinked">Hazards linked to incidents</button>
    <button class="btn " @click="showClosed">Closed Hazards</button></div>
        <div class="card">
          <div class="card-body">
    <HazardsTable ref="hazardsTable" :list="hazardList" />
      </div>
          </div>
      </div>
      </div>


    </div>
</template>

<script>

import HazardsTable from "@/components/tables/HazardsTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
export default {
  name: "Hazards",
  components: {
    HazardsTable,MaterialButton,
  },
  //data can be replaced with API data
  data() {
    return {
      filterType: 'all',
      hazardList: [
        {
          updatedDate:"24/04/2024",
          reportDate:"24/04/2024",
          title:"Pot Hole",
          location:"Area 52",
          incidents: "Linked",
          status:"Open Hazard",
          id: 123456
        },
        {
          updatedDate:"04/04/2024",
          reportDate:"05/04/2024",
          title:"Dock not draining",
          location:"The left side",
          incidents: false,
          status:"Closed",
          id: 123456
        },
        {
          updatedDate:"12/04/2024",
          reportDate:"12/04/2024",
          title:"Flapping banner not secure",
          location:"Area 52",
          incidents: false,
          status:"Open Hazard",
          id: 123456
        },
        {
          updatedDate:"30/04/2024",
          reportDate:"30/04/2024",
          title:"Bad door hinge",
          location:"The left side",
          risks:"Collision",
          incidents: "Linked",
          status:"New",
          id: 123456
        },
      ],
    };
  },
  methods: {
    showLinked() {
      this.$refs.hazardsTable.showLinked();
    },
    showOpen() {
      this.$refs.hazardsTable.showOpen();
    },
    showNew() {
      this.$refs.hazardsTable.showNew();
    },
    showClosed() {
      this.$refs.hazardsTable.showClosed();
    },
    showAll() {
      this.$refs.hazardsTable.showAll();
    },
  },
};
</script>

<style scoped>
tr, .bg-gradient-info, .icon-lg{
  cursor: pointer;
}
</style>
