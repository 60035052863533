<template>
          <div class="table-responsive">
            <table id="permits" class="table table-flush" >
              <thead class="thead-light">
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Status</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Project</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Applicant</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Type</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Location</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Start</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  End</th>
                </tr>
              </thead>
              <tbody>
          <tr v-for="item in sortedList" :key="item.id" :data-id="item.id">
          <td class="text-sm font-weight-normal">
            
            <button
                  class="btn w-100 m-0 px-1 py-0 text-white"
            :class="{
              pending: item.status == 'Pending',
              waiting: item.status == 'Awaiting Approval',
              draft: item.status == 'Draft',
              cancelled: item.status == 'Cancelled',
              updated: item.status == 'Updated',
            }">
            {{ item.status }}
                </button></td>
          <td class="text-sm font-weight-normal">{{ item.title }}</td>
          <td class="text-sm font-weight-normal">{{ item.applicant }}</td>
          <td class="text-sm font-weight-normal">{{ item.type }}</td>
          <td class="text-sm font-weight-normal">{{ item.location }}</td>
          <td class="text-sm font-weight-normal">{{ item.startDate }}</td>
          <td class="text-sm font-weight-normal">{{ item.endDate }}</td>
        </tr>
              </tbody>
            </table>
          </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import moment from "moment";

export default {
  name: "PermitsTable",
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dataTable: null,
    };
  },
  computed: {
    sortedList() {
      // Sort the list by start date
      return this.list.slice().sort((a, b) => {
        const dateA = moment(a.startDate, "DD/MM/YYYY");
        const dateB = moment(b.startDate, "DD/MM/YYYY");
        return dateB - dateA;
      });
    },
  },
  mounted() {
    this.initDataTable();
  },
  methods: {
    initDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
      }
      this.dataTable = new DataTable("#permits", {
        searchable: true,
        fixedHeight: true,
      });  
      
      // Add link to row
      const tableBody = document.querySelector("#permits tbody");
        const rows = tableBody.querySelectorAll("tr");
        rows.forEach((row) => {
          const id = row.dataset.id;
          row.addEventListener("click", () => {
            console.log('Row clicked', id); // Verify the click event
            this.$router.push(`/permit/${id}`);
          });
      });
},
      // view by status
    showDrafts() {
      this.initDataTable();
      const tableBody = document.querySelector("#permits tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Draft") {
          row.remove();
        }
      });
    },
    showPending() {
      this.initDataTable();
      const tableBody = document.querySelector("#permits tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Pending") {
          row.remove();
        }
      });
    },
    showWaiting() {
      this.initDataTable();
      const tableBody = document.querySelector("#permits tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Awaiting Approval") {
          row.remove();
        }
      });
    },
    showUpdated() {
      this.initDataTable();
      const tableBody = document.querySelector("#permits tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Updated") {
          row.remove();
        }
      });
    },
    showCancelled() {
      this.initDataTable();
      const tableBody = document.querySelector("#permits tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Cancelled") {
          row.remove();
        }
      });
    },
    showAll() {
      this.initDataTable();
    },
  },
};
</script>
<style scoped>
tr{
  cursor: pointer;
}
</style>
