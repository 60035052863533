<template>
  <div
    class="pt-3 bg-white multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="mt-3 row text-start">
    <div class="col-12">
      <div class="mt-4">
            <material-input
              id="location"
              variant="dynamic"
              label="Location"
            />
      </div>
    </div>
    <div class="col-12">
      <div class="mt-4"><h4>You can select location and time on the map</h4>
        <base-map></base-map>
      </div>
    </div>
      </div>
      <div class="row">
        <div class="mt-4 button-row d-flex col-12">
          <button
            class="mb-0 btn bg-gradient-light js-btn-prev"
            type="button"
            title="Prev"
            @click="$parent.prevStep"
          >
            Prev
          </button>
        <button
          class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="$parent.nextStep"
        >
          Next
        </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Choices from "choices.js";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import baseMap from "@/components/maps/baseMap.vue";

export default {
  name: "TheAddress",
  components: {
    MaterialInput,
    baseMap,
  },
  mounted() {
    if (document.getElementById("choices-country")) {
      var country = document.getElementById("choices-country");
      new Choices(country, {
        allowHTML: true,
      });
    }
  },
};
</script>
