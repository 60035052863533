<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
      <h4 class="m-3">Port Personnel</h4> </div>
      <div class="col-6 text-end">
              <router-link to="/add-people" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Add People
                  </MaterialButton></router-link>
                </div>
      
    <div class="row">
      <div class="p-3 pb-0 card-body">
    <button class="btn" @click="showPortPersonnel">Port Personnel</button>
    <button class="btn" @click="showContractor">Contractors</button>
    <button class="btn " @click="showAll">All</button>
        <div class="card">
          <div class="card-body">
    <PersonnelTable ref="peopleTable" :list="peopleList" />
      </div>
          </div>
      </div>
      </div>


    </div>
    </div>
</template>

<script>

import PersonnelTable from "@/components/tables/PersonnelTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
export default {
  name: "Contractors",
  components: {
    PersonnelTable,MaterialButton
  },
  //data can be replaced with API data
  data() {
    return {
      filterType: 'all',
      peopleList: [        
        {
          name:"Quinn White",
          company:"Fumigation Systems",
          startDate:"24/04/2024",
          reportsTo:"Dave Monkey",
          role:"Service Worker",
          status:"Contractor",
          id: 123456
        },     
        {
          name:"Rufus Dogface",
          company:"Bright Spark",
          startDate:"24/04/2024",
          reportsTo:"Ed White",
          role:"Electrcian",
          status:"Contractor",
          id: 123456
        },     
        {
          name:"Gary Cat",
          company:"The Port",
          startDate:"24/04/2024",
          reportsTo:"Mr Admin",
          role:"Supervisor",
          status:"Port Personnel",
          id: 123456
        },     
        {
          name:"Bob Dobalina",
          company:"The Port",
          startDate:"24/04/2024",
          reportsTo:"Gary Cat",
          role:"Forklift Driver",
          status:"Port Personnel",
          id: 123456
        },     
        {
          name:"Barry White",
          company:"Trukers",
          startDate:"24/04/2024",
          reportsTo:"Gary Cat",
          role:"Truck Driver",
          status:"Contractor",
          id: 123456
        },     
      ],
    };
  },
  methods: {
    showContractor() {
      this.$refs.peopleTable.showContractor();
    },
    showPortPersonnel() {
      this.$refs.peopleTable.showPortPersonnel();
    },
    showAll() {
      this.$refs.peopleTable.showAll();
    },
  },
};
</script>

<style scoped>
tr, .bg-gradient-info{
  cursor: pointer;
}
</style>
