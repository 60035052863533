<template>
  <div
    class="pt-3 bg-white multisteps-form__panel js-active position-relative"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="mt-3 row">
          <div class="d-block mb-4">
            <material-input
              id="title"
              variant="dynamic"
              label="Template Name"
            />
          </div>
      </div>
      <div class="mt-3 row text-start">
      <h4>Details</h4>
        <div class="mt-4 col-12 col-sm-6 d-flex">
          <material-radio id="site-work" name="radio" checked>Site Works</material-radio>
          <material-radio id="permit" name="radio">Permit</material-radio>
        </div>
          <div class="d-block mb-4">
                <material-input
                id="applicant" label="Qualifications Required"
                variant="static" class="mt-4 ms-0"/>
                <material-input
                id="applicant" label="Associated Assets"
                variant="static" class="mt-4 ms-0"/>
          </div>
      </div>
      <div class="mt-4 button-row d-flex">
        <button
          class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="$parent.nextStep"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialRadio from "@/components/materialKit/MaterialRadio.vue";

export default {
  name: "Start",
  components: { MaterialInput,MaterialRadio },
  data() {
    return {
    };
  },
};
</script>
