<template>
  <div class="mapContainer" style="width:100%; height:90vh; display:block;position:relative; border-radius:10px;">

  <div id="mapViewDiv"></div>
  </div>
</template>

<script setup>

  //#region imports

  import { ref, watch, onMounted } from 'vue'
  
  import WebMap from "@arcgis/core/WebMap.js";
  import config from "@arcgis/core/config.js";
  import * as urlUtils from '@arcgis/core/core/urlUtils';
  import MapView from "@arcgis/core/views/MapView.js";
  import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';
  import "@arcgis/core/assets/esri/themes/light/main.css";

  import EsriHelper from "@/services/esriHelper.js"
  const esriHelper = new EsriHelper();
  const props = defineProps({
  mapType: {
    type: String,
    required: true 
  }
})
  let mapView;
  let pamFeatureLayer;
  let pamWharfChainageLayer;
  let pamConfinedSpacesLayer;
  let pamShipVisitsLayer;

  //vue reactive properties
  const loadingMaps = ref(false);
  const mapMode = ref("LiveView");

  //#region watches

  watch(()=>props.mapType, (newMode) => {
    mapMode.value = newMode;
  })
  
  function setupMenuPanelAndMapControls()
  {
    mapView.ui.move("zoom", "bottom-right");


    var timeFilterButton = document.getElementById('timeFilterButton');
    mapView.ui.add(timeFilterButton, "top-right");

    //display loading indicator
    var loadingIndicator = document.getElementById('mapsLoadingIndicator');
    mapView.ui.add(loadingIndicator, "top-right");


  }
  //#region arcgis map setup

  onMounted(async () => {
    console.log(`the component is now mounted.`)

    mapMode.value = props.mapType;

    await esriHelper.initialize(urlUtils);
    config.apiKey = esriHelper.ApiKey;

    const webMap = new WebMap({
      portalItem: {
        id: esriHelper.WebmapId
    }});

    mapView = new MapView({
      map: webMap,
      container: "mapViewDiv",
      padding: {
        left: 49
      }
    });

    setupMenuPanelAndMapControls();

    webMap.when(() => {
      
      loadingMaps.value = true;
      
      const { title, description, thumbnailUrl } = ref('webMap.portalItem');
onMounted(() => {
  const { title: itemTitle, description: itemDescription, thumbnailUrl: itemThumbnailUrl } = webMap.portalItem;
  title.value = itemTitle;
  description.value = itemDescription;
  thumbnailUrl.value = itemThumbnailUrl + '&token=' + config.apiKey;
}); //needs token to be able to access

      //get a reference to layer we want to manipulate - PAM Prototype (which has layers for Bunkering, Hot Works and Site Work Alerts)
      //https://tucumcari.maps.arcgis.com/home/item.html?id=b9ae6977eb194682b1c60370c5204f52
      for(let item of webMap.allLayers.items)
      {
        if(item.title == 'Work Items')
          pamFeatureLayer = item;

        if(item.title == 'Wharf Chainage')
          pamWharfChainageLayer = item;

        if(item.title == 'Confined Spaces')
          pamConfinedSpacesLayer = item;

        if(item.title == 'Ship Visits')
          pamShipVisitsLayer = item;
      }

      
      //want to know when all layers are loaded
      Promise.all([
        mapView.whenLayerView(pamFeatureLayer),
        mapView.whenLayerView(pamWharfChainageLayer),
        mapView.whenLayerView(pamConfinedSpacesLayer),
        mapView.whenLayerView(pamShipVisitsLayer)
      ]).then(([layerView1, layerView2, layerView3, layerView4]) => {
          return Promise.all(
            [
              reactiveUtils.whenOnce(() => !layerView1.updating),
              reactiveUtils.whenOnce(() => !layerView2.updating),
              reactiveUtils.whenOnce(() => !layerView3.updating),
              reactiveUtils.whenOnce(() => !layerView4.updating)
            ]
          );
      }).then(() => {
        console.log("done updating")
        loadingMaps.value = false;
      });

      
    });

  })
</script>
<style >

  #mapViewDiv {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    background-color: white;
    border-radius:10px;
  }
  calcite-loader {
    align-self: center;
    justify-self: center;
  }
  /* hide map focus border that is auto created by Calcite - looks ugly*/
  .esri-view .esri-view-surface:focus::after {
    outline: 0px !important;
  }
</style>
<style src="@esri/calcite-components/dist/calcite/calcite.css"></style>