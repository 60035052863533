<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-4 py-1 container-fluid">
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0"
      >
        <a href="#" class="p-0 nav-link text-body d-none d-xl-flex" @click.prevent="minNav">
          <div class="sidenav-toggler-inner">
            <i
              class="sidenav-toggler-line"
              :class="color ? 'bg-white' : ''"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="color ? 'bg-white' : ''"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="color ? 'bg-white' : ''"
            ></i>
          </div>
        </a>
      <breadcrumbs :color="color" class="ms-6"/>
      </div>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4  justify-content-end"
      >
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-xl-none px-3 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-body lh-1"
              @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>

          
          <li
            class="nav-item dropdown d-flex align-items-center pe-2"
          >
            <a
              id="dropdownMenuButton"
              href="#"
              class="p-0 nav-link lh-1"
              :class="[color, showMenu ? 'show' : '']"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            ><span class="align-middle d-inline-block pb-2">3 Notifications</span> 
              <i class="material-icons cursor-pointer danger"> notifications </i> 
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <router-link to="/permit/123456" class="dropdown-item border-radius-md">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New notice</span> Bunkering for site A permit has been updated
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        13 minutes ago
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="mb-2">
                <router-link to="/incident/123456" class="dropdown-item border-radius-md">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New notice</span> New incident has been logged for Crane Malfunction
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        2 hours ago
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="mb-2">
                <router-link to="/profile" class="dropdown-item border-radius-md">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Go to all notices</span>
                      </h6>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>


          <li class="nav-item d-flex align-items-center px-2 mb-1">
            <router-link
              :to="{ name: 'Profile' }"
              class="px-0 nav-link font-weight-bold lh-1"
              :class="color"
            >
              <i class="material-icons">
                account_circle
              </i>
            </router-link>
          </li>
          <li class="nav-item d-flex align-items-center px-2 mb-1">
            <router-link
              to="/sign-in"
              class="px-0 nav-link font-weight-bold lh-1"
              :class="color"
            >
              <i class="material-icons">
                logout
              </i>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "@/layout/Breadcrumbs.vue";
import { mapMutations } from "vuex";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
  },
  props: {
    minNav: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: "text-body",
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },

  methods: {
    ...mapMutations(["navbarMinimize"]),

    toggleSidebar() {
      this.navbarMinimize();
    },
  },
};
</script>
