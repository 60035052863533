<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-4 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">New incidents reported</h5> 
              </div>
              <div class="col-lg-3 col-sm-12 text-end"> 
              <router-link to="/incidents">
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning"
                  ><h5 class="text-white mt-3">8</h5>
                </div>
              </router-link>
              </div>
              </div>
            </div>
          </div>
        </div>
      <div class="col-lg-4 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Total incidents in review</h5> 
              </div>
              <div class="col-lg-3 col-sm-12 text-end">  
              <router-link to="/incidents">
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning"
                  ><h5 class="text-white mt-3">12</h5>
                </div>
              </router-link>
              </div>
              </div>
            </div>
          </div>
        </div>
      <div class="col-lg-4 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Assets with an upcoming expiry</h5> 
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
              <router-link to="/asset-management">
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning"
                  ><h5 class="text-white mt-3">8</h5>
                </div>
              </router-link> 
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div class="mt-4 row">
      <div class="col-lg-9 col-sm-12">
        <div class="card h-100">
          <div class="card-header mb-0 pb-0">
            <h5 class="mb-0 pb-0 text-start">Permits/Site Works</h5>
            <div class="text-end">
              <router-link to="/works-list" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >All Permits/Site Works
                  </MaterialButton></router-link>
              <router-link to="/add-permit">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Log new permit
                  </MaterialButton></router-link>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="text-end mt-1 mb-1">
    <button class="btn " @click="showAll">All</button>
    <button class="btn" @click="showWaiting">Permits awaiting my approval</button>
    <button class="btn" @click="showPending">Permits pending approval</button>
    <button class="btn" @click="showCancelled">Cancelled/declined permits</button>
    <button class="btn " @click="showDrafts">Draft Permits</button></div>
            <PermitsTable ref="permitsTable" :list="permitList" class="permitTable" />
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-12">
      <default-statistics-card
        title="Permit Presets"
        :percentage="{
        }"
        menu="Select Preset"
        :dropdown="[
          {
            label: 'Bunkering for site A',
            route: `/add-permit`,
          },
          {
            label: 'Crane and Heavy lifts',
            route: `/add-permit`,
          },
        ]"
      />
      <default-statistics-card
        title="Site Works Presets"
        :percentage="{
        }"
        menu="Select Preset"
        :dropdown="[
          {
            label: 'Repairs / Maintenance',
            route: `/add-permit`,
          },
          {
            label: 'Cruise Activity',
            route: `/add-permit`,
          },
        ]"
      />
      </div>
    </div>
  </div>
</template>

<script>

import DefaultStatisticsCard from "@/components/cards/DefaultStatisticsCard.vue";
import PermitsTable from "@/components/tables/PermitsTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
export default {
  name: "Home",
  components: {
    PermitsTable,
    DefaultStatisticsCard,
    MaterialButton,
  },
  data() {
    return {
      incidentID: 123456,
      hazardID: 123456,
        presets: [
            { title: 'Bunkering for site A', type:"Permit", link: '/add-permit' },
            { title: 'Site Work Repairs', type:"Permit", link: '/add-permit' },
            { title: 'Preset 3', type:"Site Works", link: '/add-permit' }
        ],
      permitList: [
        {
          title: "Fumigation of that place",
          applicant:"Quinn White",
          type:"Fumigation",
          startDate:"24/04/2024",
          endDate:"27/04/2024",
          location:"That Place",
          status:"Updated",
          id: 123456
        },
        {
          title: "Ship weld Hot works",
          applicant:"Rufus Stuff",
          type:"Hot Works",
          startDate:"04/04/2024",
          endDate:"05/04/2024",
          location:"That Place",
          status:"Pending",
          id: 123456
        },
        {
          title: "Confined Space for Log Yard Barrier Install",
          applicant:"Gary Cat",
          type:"Confined Space",
          startDate:"12/04/2024",
          endDate:"13/04/2024",
          location:"Left Area",
          status:"Awaiting Approval",
          id: 123456
        },
        {
          title: "Bunkering of the Bank",
          applicant:"Bob Dobalina",
          type:"Bunkering",
          startDate:"30/04/2024",
          endDate:"31/04/2024",
          location:"The Bank",
          status:"Draft",
          id: 123456
        },
        {
          title: "Inspection of the Boat",
          applicant:"Jimmy Dogface",
          type:"Inspection",
          startDate:"24/04/2024",
          endDate:"25/04/2024",
          location:"The Boat",
          status:"Cancelled",
          id: 123456
        },
      ],
    };
  },
  methods: {
    showCancelled() {
      this.$refs.permitsTable.showCancelled();
    },
    showWaiting() {
      this.$refs.permitsTable.showWaiting();
    },
    showPending() {
      this.$refs.permitsTable.showPending();
    },
    showDrafts() {
      this.$refs.permitsTable.showDrafts();
    },
    showAll() {
      this.$refs.permitsTable.showAll();
    },
  },
};
</script>
