<template>
  <div class="py-4 container-fluid">
    <div class="row">
    <div class="row mb-2">
      <div class="col-lg-4">
        <h4>{{ data.inputs.title }}</h4>
      </div>
              <div class="col-lg-8 text-end">
                <button
                  class="btn bg-gradient-primary mb-0 toast-btn me-2"
                  type="button"
                  @click="toggleDisabled"
                >
                  Edit
                </button>
              <!-- archive modal button -->
                <button
                  class="btn bg-gradient-dark mb-0 toast-btn me-2"
                  type="button"
                data-bs-toggle="modal" data-bs-target="#closeModal"
                >
                  Archive
                </button>
          <router-link to="/asset-management">
              <MaterialButton
                variant="gradient"
                color="warning"
              >Asset Management
              </MaterialButton></router-link>
            </div>
                <!-- archive Modal -->
                <div
                  id="closeModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="closeModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="closeModal" class="modal-title">
                          Close Incident
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <p>This incident is now closed, with follow up actions.</p>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Close Incident
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
    </div>
    </div>
    <div class="row">
  <div class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'active'"
          >
          <span class="ms-1">Summary</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'locations'"
          >
          <span class="ms-1">Locations</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'servicing'"
          >
          <span class="ms-1">Servicing & Contacts</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'related'"
          >
          <span class="ms-1">Related Incidents / Hazards</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="tab = 'custom'"
          >
          <span class="ms-1">Custom Intergrations</span></a
        >
      </li>
    </ul>
  </div>

    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
        <div v-show="tab === 'active'" class="p-3 pt-0 card-body mx-4 my-4" >
            <div class="row mt-4">
              <div class="col-12 col-sm-8">
                <material-input
                id="title" label="Title"
                variant="static" class="mt-2 ms-0"
                :value="data.inputs.title"
                :disabled="isDisabled"
                @update:value="(val) => (data.inputs.title = val)" />

                <material-input
                id="title" label="Make and Model"
                variant="static" class="mt-4 ms-0"
                :value="data.inputs.make"
                :disabled="isDisabled"
                @update:value="(val) => (data.inputs.make = val)" />
                <material-input
                id="title" label="Purchased from"
                variant="static" class="mt-4 ms-0"
                :value="data.inputs.purchased"
                :disabled="isDisabled"
                @update:value="(val) => (data.inputs.purchased = val)" />

<material-input
id="title" label="Warranty"
variant="static" class="mt-4 ms-0"
:value="data.inputs.warranty"
:disabled="isDisabled"
@update:value="(val) => (data.inputs.warranty = val)" />

<material-input
id="title" label="Licence"
variant="static" class="mt-4 ms-0"
:value="data.inputs.licence"
:disabled="isDisabled"
@update:value="(val) => (data.inputs.licence = val)" />

              </div>

              <div class="col-12 col-sm-4">

                <div>
                <label class="ms-0">Purchase date</label>
                  <option class="disabledLink">April 24 2012</option>
                </div>

                <label class="mt-4 ms-0">Next Service Due</label>
                <div class="input-group input-group-static">
                  <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  <flatPickr
                    v-model="date"
                    :disabled="isDisabled"
                    class="form-control datetimepicker"
                    :config="{
                      allowInput: true,
                      altInput: true, 
                      enableTime: false,
                      defaultDate: '2024-04-19T12'
                    }" />
                </div>
              </div>
            </div>

            <div class="col-12">
              <label class="mt-2 ms-0">Description</label>
                <material-textarea
                id="description"
                variant="static" class="mt-2 ms-0"
                :value="data.inputs.description" 
                :disabled="isDisabled"
                @update:value="(val) => (data.inputs.description = val)"></material-textarea>
                
        
          </div>
        </div>

        
      <div v-show="tab === 'locations'" class="p-3 pb-0 card-body" >
      <div class="mt-3 row">
        <h4>Home and recorded locations</h4>
       <base-map></base-map>

      </div>
    </div>
          
      
      <div v-show="tab === 'servicing'" class="p-3 pb-0 card-body" >
      <div class="mt-3 row">
        <div class="col-8">
        <h4>Servicing History</h4>
            <div v-if="!isDisabled" class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                data-bs-toggle="modal" data-bs-target="#serviceModal"
                >Add entry
                </button>
             </div>
                <!-- Service Modal -->
                <div
                  id="serviceModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="serviceModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="serviceModal" class="modal-title">
                          Enter service details
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <form>
                          <material-input
                          id="name" label="Booked By"
                          variant="static" class="mt-4 ms-0" />
                          <material-input
                          id="company" label="Booked for"
                          variant="static" class="mt-4 ms-0" />
                          <material-input
                          id="name" label="Note"
                          variant="static" class="mt-4 ms-0" />
                        </form>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Add
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
          <div
          v-for="(service, index) in services" :key="index"
          class="mb-4 border card-plain border-radius-lg p-4">
            <h6 class="text-dark font-weight-bold mb-0">Booked by: {{ service.bookedBy }}</h6>
            <div class="row">
                <div class="col-12">
                    <p class="text-sm mt-1 mb-0">
                      <b>Date serving due: </b>
                      {{ service.when }} </p>
                    <p class="text-sm mt-1 mb-0">
                      <b>Date serving completed:</b>
                      {{ service.due }} </p>
                    <p class="text-sm mt-1 mb-0">
                      <b>Note:</b>
                      {{ service.note }} </p>
                </div>
            </div>
          </div>

        </div>
        <div class="col-4">
          <h4>Contacts</h4>
            <div v-if="!isDisabled" class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                data-bs-toggle="modal" data-bs-target="#contactModal"
                >Add contact
                </button>
             </div>
                <!-- Action Modal -->
                <div
                  id="contactModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="contactModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="contactModal" class="modal-title">
                          Add contact
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <form>
                          <material-input
                          id="name" label="Main contact"
                          variant="static" class="mt-4 ms-0" />
                          <material-input
                          id="company" label="Company"
                          variant="static" class="mt-4 ms-0" />
                          <material-input
                          id="name" label="Phone"
                          variant="static" class="mt-4 ms-0" />
                          <material-input
                          id="email" label="Emails"
                          variant="static" class="mt-4 ms-0" />
                        </form>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Add
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>
          <div
          v-for="(incidentContact, index) in incidentContacts" :key="index"
          class="mb-4 border card-plain border-radius-lg p-4">
            <h6 class="text-dark font-weight-bold mb-0">{{ incidentContact.mainContact }}</h6>
            <div class="row">
                <div class="col-12">
                    <p class="text-sm mt-1 mb-0">
                      <b>Company: </b> <br>
                      {{ incidentContact.company }} </p>
                    <p class="text-sm mt-1 mb-0">
                      <b>Phone:</b> <br>
                      {{ incidentContact.phone }} </p>
                    <p class="text-sm mt-1 mb-0">
                      <b>Email:</b> <br>
                      {{ incidentContact.email }} </p>
                </div>
            </div>
          </div>
        </div>

      </div>
    </div>
      
      <div v-show="tab === 'related'" class="p-3 pb-0 card-body" >
      <div class="mt-3 row">
        
        <div class="table-responsive">
            <table id="datatable-basic" class="table table-flush" >
              <thead class="thead-light">
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Status
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    First Reported
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Incident occured
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Title
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Location
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Type
                  </th>
                </tr>
              </thead>
              <tbody>
        <tr
            v-for="item in safety" :key="item.id" :data-id="item.id">
          <td class="text-sm font-weight-normal">
            <button
                  class="btn w-100 mb-0 p-0 text-white"
            :class="{
              draft: item.status == 'New',
              review: item.status == 'In Review',
              openIcid: item.status == 'Open Incident',
              closed: item.status == 'Closed',
            }">
            {{ item.status }}
                </button></td>
          <td class="text-sm font-weight-normal">{{ item.reportDate }}</td>
          <td class="text-sm font-weight-normal">{{ item.occurDate }}</td>
          <td class="text-sm font-weight-normal">{{ item.title }}</td>
          <td class="text-sm font-weight-normal">{{ item.location }}</td>
          <td class="text-sm font-weight-normal">
            <button
            class="btn w-100 mb-0 p-0 text-white"
                  :class="{
              openIcid: item.type == 'Hazard',
              new: item.type == 'Incident',
            }"
            @click="$router.push('/hazard/123456')">
            {{ item.type }}
                </button></td>
        </tr>
              </tbody>
            </table>
          </div>
      </div>
      </div>
      
      <div v-show="tab === 'custom'" class="p-3 pb-0 card-body" >
        <div class="mt-3 row">
          <h4>Custom Intergrations</h4>
      <div class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                data-bs-toggle="modal" data-bs-target="#actionModal"
                >Add intergrations
                </button></div>
                <!-- Action Modal -->
                <div
                  id="actionModal"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="actionModal"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="actionModal" class="modal-title">
                          Add intergration
                        </h5>
                        <MaterialButton
                          color="none"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </MaterialButton>
                      </div>
                      <div class="modal-body">
                        <form>
                        <label class="form-control mb-0">Intergration</label>
                              <material-textarea
                              id="action"
                              variant="static" class="ms-0"></material-textarea>
                        </form>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <MaterialButton
                          variant="gradient"
                          color="dark"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </MaterialButton>
                        <MaterialButton
                          variant="gradient"
                          color="info"
                          class="mb-0"
                          data-bs-dismiss="modal"
                        >
                          Add
                        </MaterialButton>
                      </div>
                    </div>
                  </div>
                </div>

              <div class="col-12 mb-4">
                <label class="form-control mb-0">Intergration</label>
                <material-textarea
                id="description"
                variant="static" class="ms-0"
                :value="data.inputs.action" 
                :disabled="isDisabled"
                @update:value="(val) => (data.inputs.action = val)"></material-textarea>
              </div>
          </div>
        </div>
      <div v-if="!isDisabled" class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                  @click="toggleDisabled"
                >
                  Save
                </button>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import Choices from "choices.js";
import flatPickr from "vue-flatpickr-component";
import setNavPills from "@/assets/js/nav-pills.js";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import baseMap from "@/components/maps/baseMap.vue";
export default {
  name: "Incident",
  components: {
    MaterialInput,
    MaterialTextarea,
    flatPickr,
    MaterialButton,
   baseMap,
  },
  data() {
    return {
      tab: "active",
      isDisabled: true,
      data: {
        inputs: {
          title:"Forklift",
          location:"Area 52",
          make:"LiuGong 3.5Ton Diesel Engine",
          updatedBy:"Gary Cat",
          hazards: "Pot hole",
          asset:"Forklift",
          description:"Rated capacity: 3500kg, Load center: 500mm, Unladen mass:4900kg, Maximum lift height:4500mm, Tyres type: solid tyre",
          id: 123456,
          action:"No intergrations added",
          nextService:"",
          booked:"not yet booked",
          warranty:"out of date",
          licence:"Forklift licence required",
          purchased:"Bob's Forklifts",
        },
      },
      
      safety: [
        {
          occurDate:"24/04/2024",
          reportDate:"24/04/2024",
          title:"Forklist accident",
          location:"Area 52",
          status:"In Review",
          type: "Hazard",
          id: 123456
        },
        {
          occurDate:"04/04/2024",
          reportDate:"05/04/2024",
          title:"Crane Malfunction",
          location:"The left side",
          status:"Closed",
          type: "Hazard",
          id: 123456
        },
        {
          occurDate:"12/04/2024",
          reportDate:"12/04/2024",
          title:"Forklist accident",
          location:"Area 52",
          status:"Open Incident",
          type: "Incident",
          id: 123456
        },
        {
          occurDate:"30/04/2024",
          reportDate:"30/04/2024",
          title:"Crane Malfunction",
          location:"The left side",
          status:"New",
          type: "Incident",
          id: 123456
        },
      ],
      services:[
        {
          bookedBy:"Gary Cat",
          when:"12/04/2023",
          due:"24/02/2023",
          note:"Watch out for bald tyres",
        },
        {
          bookedBy:"Gary Cat",
          when:"12/04/2022",
          due:"24/02/2022",
          note:"Passed",
        },
        {
          bookedBy:"Gary Cat",
          when:"12/04/2021",
          due:"24/02/2021",
          note:"Passed",
        },
      ], 
      incidentContacts:[
        { 
          mainContact: 'Jeff Bat : Forklift Servicing', 
          company: 'Forklift Wheels', 
          phone: '021 123456' ,
          email: 'forklift@test.com',
          linked: 'For Repairs',
        },
        { 
          mainContact: 'Gary Cat : Responsible for Asset', 
          company: 'Port Personal', 
          phone: '021 123456' ,
          email: 'port@test.com',
          linked: 'Reported Incident',
        }
      ],
    };
  },
  mounted() {
    setNavPills();

    this.getChoices("location-edit");
    this.getChoices("reviewer-edit");
    
    if (document.getElementById("asset-edit")) {
      var asset = document.getElementById("asset-edit");
      new Choices(asset, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }
    
    if (document.getElementById("hazard-edit")) {
      var hazard = document.getElementById("hazard-edit");
      new Choices(hazard, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }

  },
  methods: {
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: true,
          allowHTML: true,
        });
      }
    },
    toggleDisabled() {
    this.isDisabled = !this.isDisabled;
},

  },
};
</script>

