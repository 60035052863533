<template>
  <div class="py-4 container-fluid">
    <div class="row mb-5">
      <div class="col-lg-8 mt-lg-0 mt-4">

        <div class="card mt-4">
          <div class="card-header">
                <h5 class="mb-1 font-weight-bolder"> {{ name }} from {{ comapny }}</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-12">
                <material-input
                  id="firstName"
                  variant="static"
                  label="Name"
                :value="name" 
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <material-input
                  id="email"
                  type="email"
                  variant="static"
                  label="Email"
                  placeholder="example@email.com"
                />
              </div>
              <div class="col-6">
                <material-input
                  id="phoneNumber"
                  type="phone"
                  variant="static"
                  label="Phone Number"
                  placeholder="+64 1234 5678"
                />
              </div>
            </div>
            <div class="row mt-4">
                <h5 class="mb-1 font-weight-bolder">Work Details</h5>
              <div class="col-6">
                <div class="p-3">
                <label class="form-label mt-4 ms-0">Type</label>
              <material-checkbox id="checkboxId" checked>Contractor</material-checkbox>
              <material-checkbox id="checkboxId">Port Personnel</material-checkbox>
              </div>
            </div>
              <div class="col-6">
                <label class="form-label mt-4 ms-0">Roles</label>
                <input
                  id="choices-skills"
                  class="form-control mt-0"
                  type="text"
                  value="Safety, Supervisor"
                  placeholder="Enter something"
                  onfocus="focused(this)"
                  onfocusout="defocused(this)"
                />
            </div>

            </div>
            <div class="row mt-4">
              <div class="col-6">
                <material-input
                id="title" label="Work Role"
                variant="static" class="ms-0 mt-4"
                :value="workRole"  />
              </div>
              <div class="col-6">
                <material-input
                id="title" label="Company"
                variant="static" class="mt-4 ms-0" 
                :value="company" />
              </div>
              <div class="col-12">
                <material-input
                id="title" label="Reports to"
                variant="static" class="mt-4 ms-0" 
                :value="reportsTo" />
              </div>

              <div class="col-12 mt-4">
                <h5 class="mb-1 font-weight-bolder">Qualifications</h5>
                <material-input
                id="title" label="Licences"
                variant="static" class="mt-4 ms-0" />

                <material-input
                id="title" label="Qualifications"
                variant="static" class="mt-4 ms-0" />
                
                <h5 class="mb-1 font-weight-bolder mt-4">Extra</h5>

              <label class="mt-2 ms-0">Notes</label>
                <material-textarea
                id="description"
                variant="static" class="mt-2 ms-0"
                :value="desc" ></material-textarea>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-lg-4">
          <button
            class="mb-0 mt-4 btn bg-gradient-info"
            type="button"
            title="Site Works / Permits"
            @click="$router.push('/works-list')"
          >
          Site Works / Permits
          </button>
        <div class="card mt-4">
            <div class="card-header">
              <h5>Assets Managed</h5>
            </div>
            <div class="card-body pt-0">
                  <router-link
                  to="/asset/123456">
              <p class="text-sm">Site A Forklift</p></router-link>
              <hr>
                  <router-link
                  to="/asset/123456">
              <p class="text-sm">Crane B</p></router-link>
            </div>
        </div>

        <div class="card mt-4">
            <div class="card-header">
              <h5>Linked to Incidents</h5>
            </div>
            <div class="card-body pt-0">
                  <router-link
                  to="/incident/123456">
              <p class="text-sm">Forklift accident</p></router-link>
              <hr>
                  <router-link
                  to="/incident/123456">
              <p class="text-sm">Crane Malfunction</p></router-link>
            </div>
        </div>

        <div class="card mt-4">
            <div class="card-header">
            <h5>Linked to Hazards</h5>

            </div>
            <div class="card-body pt-0">
                  <router-link
                  to="/hazard/123456">
              <p class="text-sm">Pot Hole</p></router-link>
              <hr>
                  <router-link
                  to="/hazard/123456">
              <p class="text-sm">Bad door hinge</p></router-link>
            </div>
          </div>

    </div>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
import MaterialCheckbox from "@/components/materialKit/MaterialCheckbox.vue";

export default {
  name: "SideNavItem",
  components: {
    MaterialInput,MaterialTextarea,MaterialCheckbox
  },
  data() {
    return {
      name:"Rufus Dogface",
      company:"Bright Spark",
      workRole:"Electrician",
      reportsTo: "Gary Cat",
      desc:"Likes to annoy cats but only responds to email and good boy",

    };
  },
  mounted() {

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }
  },
};
</script>

