<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
      <h4 class="m-3">Site Incidents</h4> </div>
      <div class="col-6 text-end">
              <router-link to="/active-work" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Recent Incidents (Map View)
                  </MaterialButton></router-link>
              <router-link to="/report-issue" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Report Incident
                  </MaterialButton></router-link>
          <router-link to="/hazards">
              <MaterialButton
                variant="gradient"
                color="warning"
              >Hazards List
              </MaterialButton></router-link>
                </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">New incidents reported</h5> 
              </div>
              <div class="col-lg-3 col-sm-12 text-end"> 
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-danger"
                  @click="showNew"
                  ><h5 class="text-white mt-3">8</h5>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Total incidents in review</h5> 
              </div>
              <div class="col-lg-3 col-sm-12 text-end"> 
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-danger"
                  @click="showReview"
                  ><h5 class="text-white mt-3">12</h5>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      
    <div class="row">
      <div class="p-3 pb-0 card-body">
    <button class="btn " @click="showAll">All</button>
    <button class="btn" @click="showNew">New Incidents</button>
    <button class="btn" @click="showReview">Incidents in Review</button>
    <button class="btn" @click="showOpen">Open Incidents</button>
    <button class="btn " @click="showClosed">Closed Incidents</button></div>
        <div class="card">
          <div class="card-body">
    <IncidentsTable ref="incidentsTable" :list="incidentList" />
      </div>
          </div>
      </div>
      </div>


    </div>
</template>

<script>

import IncidentsTable from "@/components/tables/IncidentsTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
export default {
  name: "Incidents",
  components: {
    IncidentsTable,MaterialButton,
  },
  //data can be replaced with API data
  data() {
    return {
      incidentList: [
        {
          occurDate:"24/04/2024",
          reportDate:"24/04/2024",
          title:"Forklist accident",
          location:"Area 52",
          hazards: true,
          status:"In Review",
          asset: true,
          id: 123456
        },
        {
          occurDate:"04/04/2024",
          reportDate:"05/04/2024",
          title:"Crane Malfunction",
          location:"The left side",
          hazards: true ,
          status:"Closed",
          asset: true,
          id: 123456
        },
        {
          occurDate:"12/04/2024",
          reportDate:"12/04/2024",
          title:"Forklist accident",
          location:"Area 52",
          hazards: false,
          status:"Open Incident",
          asset: true,
          id: 123456
        },
        {
          occurDate:"30/04/2024",
          reportDate:"30/04/2024",
          title:"Crane Malfunction",
          location:"The left side",
          hazards: false,
          status:"New",
          asset:"",
          id: 123456
        },
      ],
    };
  },
  methods: {
    showReview() {
      this.$refs.incidentsTable.showReview();
    },
    showOpen() {
      this.$refs.incidentsTable.showOpen();
    },
    showNew() {
      this.$refs.incidentsTable.showNew();
    },
    showClosed() {
      this.$refs.incidentsTable.showClosed();
    },
    showAll() {
      this.$refs.incidentsTable.showAll();
    },
  },
};
</script>

<style scoped>
tr, .bg-gradient-info, .icon-lg{
  cursor: pointer;
}
</style>
