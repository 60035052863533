<template>
          <div class="table-responsive">
            <table id="templates" class="table table-flush" >
              <thead class="thead-light">
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Status</th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Type</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Template</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >Code</th>
                </tr>
              </thead>
              <tbody>
          <tr v-for="item in list" :key="item.id" :data-id="item.id">
          <td class="text-sm font-weight-normal">
            <button
                  class="btn w-100 m-0 px-1 py-0 text-white"
            :class="{
              new: item.status == 'Active',
              cancelled: item.status == 'Archived',
            }">
            {{ item.status }}
                </button></td>
          <td class="text-sm font-weight-normal">
            <button
                  class="btn w-100 m-0 px-1 py-0 text-white"
            :class="{
              waiting: item.type == 'Permit',
              pending: item.type == 'Site Work',
            }">
            {{ item.type }}
                </button></td>
          <td class="text-sm font-weight-normal">{{ item.template }}</td>
          <td class="text-sm font-weight-normal">{{ item.code }}</td>
        </tr>
              </tbody>
            </table>
          </div>
</template>

<script>
import { DataTable } from "simple-datatables";

export default {
  name: "TemplatesTable",
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dataTable: null,
    };
  },
  mounted() {
    this.initDataTable();
    this.showActive();
  },
  methods: {
    initDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
      }
      this.dataTable = new DataTable("#templates", {
        searchable: true,
    paging: false
      });  
      // Add link to row
      const tableBody = document.querySelector("#templates tbody");
        const rows = tableBody.querySelectorAll("tr");
        rows.forEach((row) => {
          row.addEventListener("click", () => {
            console.log('Row clicked'); // Verify the click event
            this.$router.push(`/edit-template`);
          });
      });
},
    showActive() {
      this.initDataTable();
      const tableBody = document.querySelector("#templates tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Active") {
          row.remove();
        }
      });
    },
    showArchived() {
      this.initDataTable();
      const tableBody = document.querySelector("#templates tbody");
      const rows = tableBody.querySelectorAll("tr");
      rows.forEach((row) => {
        const statusCell = row.querySelector("td:nth-child(1)");
        if (statusCell && statusCell.textContent.trim() !== "Archived") {
          row.remove();
        }
      });
    },
    showPermits() {
  this.initDataTable();
  const tableBody = document.querySelector("#templates tbody");
  const rows = tableBody.querySelectorAll("tr");
  rows.forEach((row) => {
    const statusCell = row.querySelector("td:nth-child(2)");
    if (statusCell && statusCell.textContent.trim() !== "Permit") {
      row.remove();
    }
  });
},
showSiteWorks() {
  this.initDataTable();
  const tableBody = document.querySelector("#templates tbody");
  const rows = tableBody.querySelectorAll("tr");
  rows.forEach((row) => {
    const statusCell = row.querySelector("td:nth-child(2)");
    if (statusCell && statusCell.textContent.trim() !== "Site Work") {
      row.remove();
    }
  });
},
    showAll() {
      this.initDataTable();
    },
  },
};
</script>
<style scoped>
tr{
  cursor: pointer;
}
</style>
