<template>
  <div class="table-responsive">
    <table id="assets" class="table table-flush" >
      <thead class="thead-light">
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          > Name </th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          > Rest Location </th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          > Upcoming Expiry </th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          > Linked to incidents </th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          > Status </th>
        </tr>
      </thead>
      <tbody>
<tr v-for="item in sortedList" :key="item.id" :data-id="item.id">
  <td class="text-sm font-weight-normal">{{ item.title }}</td>
  <td class="text-sm font-weight-normal">{{ item.location }}</td>
  <td class="text-sm font-weight-normal">{{ item.expiry }}</td>
  <td class="text-sm font-weight-normal">
    <button
     class="btn w-100 mb-0 p-0 text-white"
    :class="{
      openIcid: item.incidents == 'Linked',
    }">{{ item.incidents }}</button></td>
  <td class="text-sm font-weight-normal">
    <button
    class="btn w-100 mb-0 p-0 text-white"
    :class="{
      new: item.status == 'Active',
      closed: item.status == 'Archived',
    }"> {{ item.status }} </button></td>
</tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import moment from "moment";

export default {
name: "HazardsTable",
props: {
list: {
type: Array,
required: true,
},
},
data() {
return {
dataTable: null,
};
},
computed: {
sortedList() {
// Sort the list by occur Date
return this.list.slice().sort((a, b) => {
const dateA = moment(a.reportDate, "DD/MM/YYYY");
const dateB = moment(b.reportDate, "DD/MM/YYYY");
return dateB - dateA;
});
},
},
mounted() {
this.initDataTable();
    this.showActive();
},
methods: {
initDataTable() {
if (this.dataTable) {
this.dataTable.destroy();
}
this.dataTable = new DataTable("#assets", {
searchable: true,
fixedHeight: true,
});  
// Add link to row
const tableBody = document.querySelector("#assets tbody");
const rows = tableBody.querySelectorAll("tr");
rows.forEach((row) => {
  const id = row.dataset.id;
  row.addEventListener("click", () => {
            console.log('Row clicked', id); // Verify the click event
            this.$router.push(`/asset/${id}`);
  });
});
},
// view by status
showActive() {
this.initDataTable();
const tableBody = document.querySelector("#assets tbody");
const rows = tableBody.querySelectorAll("tr");
rows.forEach((row) => {
const statusCell = row.querySelector("td:nth-child(5)");
if (statusCell && statusCell.textContent.trim() !== "Active") {
  row.remove();
}
});
},
showArchived() {
this.initDataTable();
const tableBody = document.querySelector("#assets tbody");
const rows = tableBody.querySelectorAll("tr");
rows.forEach((row) => {
const statusCell = row.querySelector("td:nth-child(5)");
if (statusCell && statusCell.textContent.trim() !== "Archived") {
  row.remove();
}
});
},
showExpire() {
this.initDataTable();
const tableBody = document.querySelector("#assets tbody");
const rows = tableBody.querySelectorAll("tr");
rows.forEach((row) => {
const statusCell = row.querySelector("td:nth-child(3)");
if (statusCell && statusCell.textContent.trim() !== "24/04/2024") {
  row.remove();
}
});
},
showLinked() {
this.initDataTable();
const tableBody = document.querySelector("#assets tbody");
const rows = tableBody.querySelectorAll("tr");
rows.forEach((row) => {
const statusCell = row.querySelector("td:nth-child(4)");
if (statusCell && statusCell.textContent.trim() !== "Linked") {
  row.remove();
}
});
},

showAll() {
this.initDataTable();
},
},
};
</script>
<style scoped>
tr{
cursor: pointer;
}
</style>
