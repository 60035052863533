<template>
  <div id="top" class="py-4 h-100 container-fluid">
    <div class="row">
        <h4>Add Asset
        </h4>
        
      <div class="col-8 mx-auto">
        <div class="card mt-4">
          <div class="card-body p-3">
                <h4 class="m-3">Details</h4> 
                
            <div class="row mt-4">
              <div class="col-12 col-sm-8">
                <material-input
                id="title" label="Title"
                variant="static" class="mt-2 ms-0" />

                <material-input
                id="title" label="Make and Model"
                variant="static" class="mt-4 ms-0"/>

                <material-input
                id="title" label="Warranty"
                variant="static" class="mt-4 ms-0" />

                <material-input
                id="title" label="Licence"
                variant="static" class="mt-4 ms-0" />

              </div>

              <div class="col-12 col-sm-4">
              <label class="ms-0">Purchase date</label>
              <div class="input-group input-group-static">
                <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                <flatPickr
                  v-model="date"
                  :disabled="isDisabled"
                  class="form-control datetimepicker"
                  :config="{
                    allowInput: true,
                    altInput: true, 
                    enableTime: false,
                  }" />
              </div>

                <label class="mt-3 ms-0">Next Service Due</label>
                <div class="input-group input-group-static">
                  <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  <flatPickr
                    v-model="date"
                    :disabled="isDisabled"
                    class="form-control datetimepicker"
                    :config="{
                      allowInput: true,
                      altInput: true, 
                      enableTime: false,
                    }" />
                </div>
              </div>
            <div class="col-12">
              <label class="mt-2 ms-0">Description</label>
                <material-textarea
                id="description"
                variant="static" class="mt-2 ms-0"></material-textarea>
          </div>
            </div>
                <h4 class="m-3">Contacts</h4> 
            <div class="row mt-4">
              <div class="col-6">
                <material-input
                id="title" label="Purchased From"
                variant="static" class="mt-4 ms-0" />
                <material-input
                id="title" label="Servicing"
                variant="static" class="mt-4 ms-0" />
                <material-input
                id="title" label="Personnel Responsible"
                variant="static" class="mt-4 ms-0" />
            </div>
              <div class="col-6">
                <material-input
                id="title" label="Purchased Contact"
                variant="static" class="mt-4 ms-0" />
                <material-input
                id="title" label="Servicing Contact"
                variant="static" class="mt-4 ms-0" />
                <material-input
                id="title" label="Personnel Responsible Contact"
                variant="static" class="mt-4 ms-0" />
            </div>
          </div>
            <div class="row mt-4">
              
      <div class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                  @click="$router.push('/asset/123456')"
                >
                  Save
                </button>
            </div>
            </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import flatPickr from "vue-flatpickr-component";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
export default {
  name: "AddAsset",
  components: {
    MaterialInput,
    MaterialTextarea,
    flatPickr,
  },  
  data() {
    return {
      tab: "active",
    };
  },
};
</script>
